import ICustomersResponse from "../../interfaces/ICustomersResponse";
import { useAxios } from "../api";
import {
  CustomerFilterRequest,
  CustomerRequest,
  CustomerResponse,
  CustomerUpdateRequest,
  CustomersResponse,
} from "./type.d";

export const useCustomerService = () => {
  const { get, post, put } = useAxios();

  const getPositivityRate = async (
    sellerId: number | undefined
  ): Promise<any> => {
    const response = await get<any>(
      `/customer/positivity-rate?seller=${sellerId}`
    );
    const { data } = response;

    return data;
  };

  const getAll = async ({
    canalDistribuicao,
    nome,
    status,
    loja,
    vendedor,
  }: CustomerFilterRequest): Promise<CustomersResponse> => {
    const response = await get<CustomersResponse>("/customer/find-all", {
      params: { canalDistribuicao, nome, status, loja, vendedor },
    });
    const { data } = response;
    return data;
  };

  const getMyCustomersByStatusAndSeller = async (
    status: string,
    idSeller: number | undefined
  ) => {
    const { data } = await get<CustomersResponse>(
      "/customer/my-clients-by-status",
      {
        params: {
          status,
          seller: idSeller,
        },
      }
    );
    return data;
  };

  const getMyCustomersBySeller = async (
    idSeller: number | undefined
  ) => {
    const { data } = await get<CustomersResponse>(
      "/customer/my-clients",
      {
        params: {
          seller: idSeller,
        },
      }
    );
    return data;
  };

  const getMyCustomersWithPurchaseBySeller = async (
    idSeller: number | undefined
  ) => {
    const { data } = await get<ICustomersResponse>(
      "/customer/my-clients-with-purchase",
      {
        params: {
          seller: idSeller,
        },
      }
    );
    return data;
  };

  const create = async (customer: CustomerRequest) => {
    const { data } = await post("/customer/create", customer);
    return data;
  };

  const update = async (customer: CustomerUpdateRequest) => {
    const { data } = await put("/customer/update", customer);
    return data;
  };

  const getById = async (id: string): Promise<CustomerResponse> => {
    const { data } = await get<CustomerResponse>(`/customer/find/${id}`);
    return data;
  };

  return {
    getAll,
    getMyCustomersByStatusAndSeller,
    getMyCustomersBySeller,
    getMyCustomersWithPurchaseBySeller,
    getPositivityRate,
    create,
    getById,
    update
  };
};
