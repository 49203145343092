import { Grid } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CollapsibleTable } from "../../components/tables/collapsibleTable";
import { CollapsibleTableProps } from "../../components/tables/collapsibleTable/type";
import { AppRoutesEnum } from "../../routes/app.routes";

export const Users: FC<CollapsibleTableProps> = ({ rows, headers }) => {
  const navigate = useNavigate();

  return (
    <Grid xs={12}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={12}>
          <p>Lista de Usuários</p>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigate(AppRoutesEnum.USER_FORM)}
            style={{ alignItems: "flex-start", marginBottom: 10 }}
          >
            Adicionar
          </Button>
        </Grid>
        <Grid item xs={11}>
          <CollapsibleTable headers={headers} rows={rows} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Users;
