import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { GoalFormInput } from "../../../../hooks/goal/type";
import { ProductsResponse } from "../../../../hooks/product/type";

export const FormGoal: FC<{
  products: ProductsResponse;
  handleSubmit: (values: GoalFormInput) => void;
  close: () => void
}> = ({ products, handleSubmit, close }) => {

  let initial = {
  } as GoalFormInput

  const formik = useFormik({
    initialValues: {
      ...initial,
    },
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    },
  });

  const [searching, setSearching] = useState('')

  const searchingLowerCase = searching.toLowerCase()

  const filteredProducts = products.filter(
    product => product.description
                .toLowerCase()
                .includes(searchingLowerCase)
  )

  console.log('searching: ', searching)

  const inputsGridsProps = {
    xs: 12,
    md: 5,
    item: true,
    pb: 2,
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid xs={12} item container direction="row" justifyContent="center">
        <Grid
          xs={12}
          lg={12}
          item
          container
          direction="column"
          justifyContent={"center"}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent={"space-between"}
          >
            <Grid {...inputsGridsProps}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                type={"date"}
                name={"startDate"}
                label={"Data de Início"}
                value={formik.values.startDate}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid {...inputsGridsProps}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                type={"date"}
                name={"endDate"}
                label={"Data Final"}
                value={formik.values.endDate}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid {...inputsGridsProps}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value = {searching}
                  onChange={ ev => setSearching(ev.target.value)}
                  label={'Procurar produto'}
                >
                </TextField>
              </FormControl>
            </Grid>

            <Grid {...inputsGridsProps}>
            </Grid>

            <Grid {...inputsGridsProps}>
              <FormControl fullWidth>
                <InputLabel id="loja-selection-id-label">Produto</InputLabel>
                <Select
                  id="loja-selection-id"
                  labelId="loja-selection-id-label"
                  label={"Produto"}
                  value={formik.values.idProduct}
                  onChange={({ target }) => {
                    formik.setFieldValue("idProduct", target.value);
                  }}
                >
                  <MenuItem value={""}>Todos produtos</MenuItem>
                  {filteredProducts.map((p, index) => (
                    <MenuItem key={`${p.id}-${index}`} value={p.id}>
                      {p.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid {...inputsGridsProps}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                type={"number"}
                name={"value"}
                label={!formik.values.idProduct ? "Valor" : "Quantidade"}
                value={formik.values.value}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid
              xs={12}
              pt={2}
              item
              container
              direction="row"
              justifyContent="right"
            >
              <Grid
                xs={12}
                lg={5}
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid md={5} xs={5} item>
                  <Button
                    fullWidth
                    type={"submit"}
                    variant="outlined"
                    disabled={formik.isSubmitting}
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid md={5} xs={5} item>
                  <Button
                    fullWidth
                    type={"button"}
                    variant="outlined"
                    disabled={formik.isSubmitting}
                    onClick={close}
                  >
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
