import { useAxios } from "../api";
import {
  GoalCreateRequest,
  GoalDeleteRequest,
  GoalFilter,
  GoalsResponse,
  GoalUpdateRequest,
} from "./type";

export const useGoalService = () => {
  const { get, post, put, del } = useAxios();

  const controllerName = "goals";
  const childControllerName = "seller";

  const getGoalsBySellerId = async (
    sellerId: number
  ): Promise<GoalsResponse> => {
    const response = await get<GoalsResponse>(
      `/${controllerName}/${childControllerName}/${sellerId}`
    );
    const { data } = response;
    return data;
  };

  const postCreateGoal = async ({
    bodyRequest,
    sellerId,
  }: GoalCreateRequest): Promise<void> => {
    await post<GoalsResponse>(
      `/${controllerName}/${childControllerName}/${sellerId}`,
      bodyRequest
    );
  };

  const putUpdateGoalById = async ({
    bodyRequest,
    id,
  }: GoalUpdateRequest): Promise<void> => {
    await put<GoalsResponse>(`/${controllerName}/${id}`, bodyRequest);
  };

  const delGoalById = async ({ id }: GoalDeleteRequest): Promise<void> => {
    await del<GoalsResponse>(`/${controllerName}/${id}`);
  };

  const getGoalsByFilter = async (
    params: GoalFilter
  ): Promise<GoalsResponse> => {
    const response = await get<GoalsResponse>(`/${controllerName}/filter`, {
      params,
    });
    const { data } = response;
    return data;
  };

  const getMyProductGoals = async (): Promise<GoalsResponse> => {
    const response = await get<GoalsResponse>(
      `/${controllerName}/my-product-goals`
    );
    const { data } = response;
    return data;
  };

  const getCurrentGoalBySeller = async (
    sellerId: number | undefined
  ): Promise<any> => {
    const response = await get<any>(
      `/${controllerName}/goal-by-seller?seller=${sellerId}`
    );
    const { data } = response;

    return data;
  };

  const getProductGoalsBySeller = async (
    sellerId: number | undefined
  ): Promise<any> => {
    const response = await get<any>(
      `/${controllerName}/product-goals-by-seller?seller=${sellerId}`
    );
    
    const { data } = response;

    return (data);
  }

  return {
    getGoalsBySellerId,
    postCreateGoal,
    putUpdateGoalById,
    delGoalById,
    getGoalsByFilter,
    getMyProductGoals,
    getCurrentGoalBySeller,
    getProductGoalsBySeller
  };
};
