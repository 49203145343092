
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import ProgressBar from "@ramonak/react-progress-bar";
import 'swiper/css';
import 'swiper/css/autoplay';
import './style.css';


const ProgressGoals = (props) => {

  return(
    <Swiper 
      modules={[Autoplay]}
      autoplay={{delay: 5000}}
    >
      { props.goals.map(g => {
          return(
            <SwiperSlide>                       

              <div className="progress-bar">
                { ((g.sold != undefined) && (g.value != undefined) && g.value > 0) && 
                  <>
                    { g.type.toUpperCase() == "META_GERAL" && 
                      <span><strong>{g.description}</strong> {g?.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                    }
                    { g.type.toUpperCase() == "META_PRODUTO" && 
                      <span><strong>{g.description}</strong> Meta: {g?.value}</span>
                    }                    
                    <ProgressBar
                      completed={
                        Math.round(g.sold / g.value * 100)
                      }
                    />
                  </>
                }              
              </div>

            </SwiperSlide>
          )
        })
      }
    </Swiper>
  )
  
}

export default  ProgressGoals
