import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRoleService } from "../../hooks/role";
import { useUserService } from "../../hooks/user";
import { UserResponse, UsersResponse } from "../../hooks/user/type";
import { RolesSubContent } from "./components/RolesSubContent";
import { ERole } from "./enum/ERole.enum";
import { Roles, StoreInterface } from "./type";
import Users from "./users";
import { UpdateSubContent } from "./components/UpdateSubContent";

export const UsersContainer = () => {
  const [items, setItems] = useState<UsersResponse>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<Roles>([]);
  const { getAll } = useRoleService();
  const { getAll: getAllUsers } = useUserService();
  const headers = ["ID", "Nome", "Email", "Acesso"];

  const loadList = async () => {
    const users = await getAllUsers();
    setItems(users);
  };

  const allRoles = async () => {
    const roles = await getAll();
    setRoles(roles);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    await Promise.all([loadList(), allRoles()]);
    setLoading(false);
  }, [isLoading, items, roles]);

  const handleChangeRoles = () => {
    //fetchData()
  }

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
          subContent: <div>Subcontent</div>,
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: ["Nenhum usuário encontrado"],
          subContent: <div>Subcontent</div>,
        },
      ];
    }

    return items.map((user: UserResponse) => {
      let rolesDescription = user.roles?.map((role) => ERole[role.name]).join(",")
      return {      
        values: [user.id, user.name, user.email, rolesDescription],
        subContent: <RolesSubContent availableRoles={roles} userId={user.id} onChangeRoles={handleChangeRoles} />,
        updateSubContent: <UpdateSubContent user={user} onChangeUser={handleChangeRoles} onChangeModal={loadList}></UpdateSubContent>
      }
    });
  }, [isLoading, items, roles]);

  useEffect(() => {
    fetchData();
  }, []);

  return <Users rows={rows} headers={headers} />;
};
