import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { useFollowupsService } from "../../../../hooks/followups";
import { FollowupResponse } from "../../../../hooks/followups/type";
import { format } from "date-fns";

export const History = ({ customerId }: { customerId: number }) => {
  const { getPaginatedFollowups } = useFollowupsService();
  const [followups, setFollowups] = useState<FollowupResponse[]>([]);
  const [page, setPage] = useState<number | undefined>(1);
  const [isLoading, setLoading] = useState<boolean>(false)

  const fetchFollowups = async () => {    
    if (!page) return;

    setLoading(true)
    const response = await getPaginatedFollowups({ page, customerId });
    setFollowups(response.data);
    setLoading(false)

    const { nextPage } = response;
    setPage(nextPage);
  };

  useEffect(() => {
    fetchFollowups();
  }, []);

  return (
    <>
      {followups.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "700px",
            overflow: "auto"            
          }}
        >
          {followups.map((item) => (
            <Grid
              key={item.id}
              item
              sx={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ccc",
                borderRadius: "10px",
                marginTop: "1vh",
                marginBottom: "1vh"                
              }}
            >
              <Grid
                item
                xs={10}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item xs={12}>
                  <p
                    style={{
                      fontSize: "1rem",
                      textAlign: "start",
                      padding: "20px",
                      margin: 0
                    }}
                  >
                    {item.description}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  
                  <p
                    style={{
                      fontSize: "0.9rem",
                      textAlign: "end",
                      margin: 0,
                      marginRight: "10px"
                    }}
                  >
                    <b
                      style={{
                        fontSize: "0.9rem",
                        textAlign: "end",
                      }}
                    >
                      {`${item.seller.name}`}
                    </b>
                    {` agendou um contato para o dia ${format(
                      new Date(item.contactScheduleAt),
                      "dd/MM/yyy HH:mm"
                    )}`}
                  </p>

                  <p
                    style={{
                      fontSize: "0.8rem",
                      textAlign: "end",
                      margin: 0,
                      padding: "10px"
                    }}
                  >
                  	<b>
                      Criado em
                    </b> 
											{	` ${
													format(
													new Date(item.createdAt),
													"dd/MM/yyy HH:mm")
													}
												`
											}
                  </p>

                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {(followups.length === 0 && !isLoading) && (
        <p style={{ fontSize: 16 }}>
          Nenhum Follow-up registrado até o momento
        </p>
      )}
      { isLoading && (
        <p style={{ fontSize: 16 }}>
          Carregando...
        </p>
      )}
    </>
  );
};
