import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { format, parse } from "date-fns";
import { useFormik } from "formik";
import { useFollowupsService } from "../../../../hooks/followups";
import swal from "sweetalert";
import { useEffect, useState } from "react";

export const FormFollowUp = ({
  customer,
  setOpen
}: {
  customer: any;
  setOpen: (value: boolean) => void;
}) => {
  const { postCreateFollowup } = useFollowupsService();
  const [status, setStatus] = useState<string>()
  const [ showCodeErp, setShowCodeErp] = useState<boolean>(false)

  const statuses = [
    { id: "PROSPECCAO", displayValue: "Prospecção" },
    { id: "QUALIFICACAO", displayValue: "Qualificação" },
    { id: "APRESENTACAO", displayValue: "Apresentação" },
    { id: "FOLLOWUP", displayValue: "Follow-Up" },
    { id: "NEGOCIACAO", displayValue: "Negociação" },
    { id: "FECHAMENTO", displayValue: "Fechamento" },
    { id: "POSVENDA", displayValue: "Pós-venda" },
    { id: "LEAD_PERDIDO", displayValue: "Lead Perdido" },
  ];

  const formik = useFormik({
    initialValues: {
      description: "",
      contactScheduleAt: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      status: customer.status,
      code: customer.code
    },
    onSubmit: (values, event) => {

      if (showCodeErp){
        if (values.code?.trim() == ""){
          alert("É necessário preencher o código do cliente")
          return
        }
      }

      postCreateFollowup({
        description: values.description,
        customerId: customer.id,
        contactScheduleAt: parse(
          values.contactScheduleAt,
          "yyyy-MM-dd'T'HH:mm",
          new Date()
        ),
        code: values.code,
        status: values.status,
      });

      swal({
        title: "Follow-up cadastrado com sucesso!",          
        icon: "success",
      }).then((value: any) => {
        setOpen(false)
      });

      event.resetForm();
    },
  });

  const { handleSubmit, handleChange, values } = formik;

  useEffect(() => {
    //quando o vendedor seleciona o status FOLLOWUP, ele precisa informar o código do cliente no BLING, para vincular o LEAD a um cliente BLING
    if (status == "FOLLOWUP"){
      setShowCodeErp(true)
    }else{
      setShowCodeErp(false)
    }
  }, [status])

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12} container direction={"row"}>
        <Grid item xs={12}>
          <h2>{customer.name}</h2>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descreva o que foi combinado no contato com o cliente"
            multiline
            rows={4}
            fullWidth
            name={"description"}
            onChange={handleChange}
            value={values.description}
          />
        </Grid>
        <Grid item xs={12} mt={3} container justifyContent={"space-between"}>
          <Grid item xs={5}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              type={"datetime-local"}
              value={values.contactScheduleAt}
              onChange={handleChange}
              name={"contactScheduleAt"}
              label={"Data do próximo contato"}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="loja-selection-id-label">Status</InputLabel>
              <Select
                id="loja-selection-id"
                labelId="loja-selection-id-label"
                label={"Status"}
                name={"status"}
                value={values.status}
                onChange={(e) => {
                  setStatus(e.target.value)
                  handleChange(e)
                }}
              >
                {statuses.filter((status) => {
                  if (status.id == "LEAD_PERDIDO"){
                    if (["PROSPECCAO","QUALIFICACAO","APRESENTACAO"].indexOf(values.status) >= 0){
                      return true
                    }else{
                      return false
                    }
                  }else if (["PROSPECCAO","QUALIFICACAO","APRESENTACAO"].indexOf(status.id) >= 0){
                    if (["PROSPECCAO","QUALIFICACAO","APRESENTACAO"].indexOf(values.status) >= 0){
                      return true
                    }else{
                      return false
                    }
                  }else if (["FOLLOWUP"].indexOf(status.id) >= 0){
                    if (["APRESENTACAO","FOLLOWUP","NEGOCIACAO","FECHAMENTO","POSVENDA","LEAD_PERDIDO"].indexOf(values.status) >= 0){
                      return true
                    }else{
                      return false
                    }
                  }else if (["NEGOCIACAO","FECHAMENTO","POSVENDA"].indexOf(status.id) >= 0){
                    if (["FOLLOWUP","NEGOCIACAO","FECHAMENTO","POSVENDA","LEAD_PERDIDO"].indexOf(values.status) >= 0){
                      return true
                    }else{
                      return false
                    }
                  }else{
                    return true
                  }
                }).map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.displayValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        { (showCodeErp || values.code) && 
          <Grid item xs={12} mt={3} container justifyContent={"space-between"}>
            <Grid item xs={5}>
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Código do Cliente no Bling"
                name={"code"}
                onChange={handleChange}
                value={values.code}
              />
            </Grid>
          </Grid>
        }
        <Grid item xs={12} mt={3} container justifyContent={"space-between"}>
          <Grid item xs={4} mt={3}>
            <Button type="submit" variant="outlined">
              Salvar
            </Button>
          </Grid>
          <Grid item xs={4} mt={3}>
            <Button
              sx={{
                color: "red",
                borderColor: "red",
                "&:hover": {
                  color: "red",
                  borderColor: "red",
                  backgroundColor: "rgba(255, 0, 0, 0.04)",
                },
              }}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
