import { LogisticsSummaryResponse } from "../../pages/Logistics/type";
import { useAxios } from "../api";
import { ProductsResponse } from "./type";

export const useProductService = () => {
  const { get } = useAxios();

  const controllerName = "produto";

  const getStock = async (): Promise<ProductsResponse> => {
    const response = await get<ProductsResponse>(`/${controllerName}/estoque`);
    const { data } = response;
    return data;
  };

  const getLogisticsSummary = async (): Promise<LogisticsSummaryResponse> => {
    const response = await get<LogisticsSummaryResponse>(`/${controllerName}/logistica/resumo`);
    const { data } = response;
    return data;
  };

  return {
    getStock,
    getLogisticsSummary
  };
};
