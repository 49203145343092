import { Button, Grid } from "@mui/material";

import "./sidebar.scss";
import { SideBarProps } from "./type";

export const Sidebar = ({ items }: SideBarProps) => {
  return (
    <Grid
      xs={12}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column"        
      }}
      item
    >
      <Grid
        xs={11}
        container
        justifyContent={"center"}
        sx={{
          marginTop: "20px",
        }}
        item
      >
        <Grid item xs={8} sx={{ marginBottom: "20px" }}>
          <img
            src="JP2Auto.png"
            alt="Logo"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Grid>

        {items &&
          items
            .filter((item) => item.isEnabled)
            .map((item, index) => (
              <Grid item xs={11} key={`sidebarButton-${index}`}>
                <Button onClick={item.onClick} fullWidth>
                  {item.title}
                </Button>
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
};
