import { 
  Box, 
  Modal, 
  Typography, 
  Button,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField, 
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { FC, useEffect, useState } from "react";
import { UserUpdateFormInterface, StoreInterface } from "../../type"
import React from "react";
import useUserService  from '../../../../services/UsersService';
import IStore from "../../../../interfaces/IStore";
import storeService from "../../../../services/StoreService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Email, Lock, Person } from "@material-ui/icons";
import usersService from "../../../../services/UsersService";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { AppRoutesEnum } from "../../../../routes/app.routes";

export const UpdateSubContent: FC<UserUpdateFormInterface> = ({
  user,
  onChangeUser,
  onChangeModal,
  setOpen
}) => {

  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [actualStore, setActualStore] = useState(user.store?.id)
  const [active, setActive] = useState(user.active)
  const [password, setPassword] = useState('')
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [selectedStore, setSelectedStore] = useState<IStore>();
  const [stores, setStores] = useState<IStore[]>([]);
  const [isLoading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  
  const selectStore = (id: string) => {
    setSelectedStore(stores.find((item) => item.id === Number(id)));
  };

  const hasCustomers = () => (user.customers.length > 0) ? true : false

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const cancelEdition = ():void => {    
    formik.resetForm();

    //se tem uma prop que é a função para fechar o elemento-pai, chama a função
    if (setOpen){
      setOpen(false)
    }
  };
  
  function openErrorModal():void {
    setIsErrorModalOpened(true);
  }

  function closeErrorModal():void {
    setIsErrorModalOpened(false);   
  }

  function openSuccessModal():void {
    setIsSuccessModalOpened(true);
  }

  function closeSuccessModal():void {
    onChangeModal()   
    setIsSuccessModalOpened(false);
  }

  const handleDelete = async (userId: number): Promise<any> => {
    return useUserService
      .remove(userId)
      .then(() => {
        return openSuccessModal()
    })
      .catch(() => {
        return openErrorModal()
      })
  }

  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      name: name,
      confPassword: "",
      store: (actualStore) ? actualStore : "",
      active: active,
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido"),
      password: Yup.string()
        .min(6, "Senha deve conter no mínimo 6 caracteres"),
      confPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Senha não corresponde"),
    }),

    onSubmit: (values) => {
      updateUser(values);
    },
  });

  const updateUser = (values: any) => {
    let data = {
      name: values.name,
      email: values.email,
      password: values.password,
      store: values.store,
      active: values.active,
    };

    usersService
      .update(user.id, data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Alteração realizada com sucesso!",
          icon: "success",
        }).then((value: any) => {
          navigate(AppRoutesEnum.USERS);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadStores();
  }, []);

  const loadStores = () => {
    storeService
      .getAll()
      .then((response) => {
        let data: IStore[] = response.data;
        data = data.filter((store) => [203404783, 204308099, 204593611].includes(store.id) ) //apenas LOJAS DE APARECIDA, SJC e CANAL LOJISTA
        setStores(data);
      })
      .catch((error) => {});
  };

  const inputsProps = {
    item: true,
    xs: 12,
    mb: 2,
  };

  return (
    <React.Fragment>
      <div>
        <div style={{paddingBottom: "20px"}}>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <h2>Alterar Dados do Usuário</h2>

                <Grid {...inputsProps}>
                  <TextField
                    name="name"
                    type="text"
                    placeholder="Nome completo"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <Person />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error_msg">{formik.errors.name}</div>
                  ) : null}
                </Grid>

                <Grid {...inputsProps}>
                  <TextField
                    name="email"
                    type="text"
                    placeholder="E-mail"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <Email />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div className="error_msg">{formik.errors.email}</div>
                  ) : null}
                </Grid>

                <Grid {...inputsProps}>
                  <TextField
                    name="password"
                    type="password"
                    placeholder="Senha"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <Lock />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error_msg">{formik.errors.password}</div>
                  ) : null}
                </Grid>

                <Grid {...inputsProps}>
                  <TextField
                    name="confPassword"
                    type="password"
                    placeholder="Confirmar senha"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <Lock />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confPassword}
                  />
                  {formik.touched.confPassword && formik.errors.confPassword ? (
                    <div className="error_msg">{formik.errors.confPassword}</div>
                  ) : null}
                </Grid>

                <Grid {...inputsProps}>
                  <FormControl fullWidth>
                    <InputLabel id="loja-selection-id-label">
                      Selecione uma loja
                    </InputLabel>
                    <Select
                      id="loja-selection-id"
                      labelId="loja-selection-id-label"
                      label={"Selecione uma loja"}
                      value={formik.values.store}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const { value } = event.target;
                        formik.setFieldValue("store", value);
                        selectStore(value);
                      }}
                    >
                      {stores.map((store) => (
                        <MenuItem key={store.id} value={store.id}>
                          {store.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.store && formik.errors.store ? (
                      <div className="error_msg">Erro na Loja</div>
                    ) : null}
                  </FormControl>
                </Grid>

                <Grid {...inputsProps}>
                  <FormControl className="condicao">
                    <InputLabel id="condicao-selection-id-label">
                      Usuário está ativo?
                    </InputLabel>
                    <Select
                      id="condicao-selection-id"
                      labelId="condicao-selection-id-label"
                      label={"Usuário está ativo?"}
                      onChange={(event) => {
                        const { value } = event.target;
                        formik.setFieldValue("active", value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.active}
                    >
                      <MenuItem key={"yes"} value={"S"}>
                        S
                      </MenuItem>
                      <MenuItem key={"no"} value={"N"}>
                        N
                      </MenuItem>
                    </Select>
                    {formik.touched.active && formik.errors.active ? (
                      <div className="error_msg">{formik.errors.active}</div>
                    ) : null}
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  container
                  mt={2}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={5}>
                    <Button className="button-confirm" type="submit">
                      Salvar
                    </Button>
                  </Grid>

                  <Grid item xs={5}>
                    <Button 
                      className="button-cancel" 
                      onClick={cancelEdition}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{display: "flex", flexFlow: "row", justifyContent: "flex-end", alignItems: "flex-end"}}>
        <div >
          { hasCustomers() &&
            <p style={{fontFamily: "Arial", fontSize: "14px", color: "red"}}>
              Este usuário não pode ser excluído <br />porque possui clientes relacionados
            </p>
          }
        </div>
        <Button 
          variant="contained" 
          color="error"
          disabled= {hasCustomers()}
          startIcon={<DeleteIcon />}
          style={{marginBottom: "20px", width: "120px", height: "50px"}}
          onClick={() => { handleDelete(user.id)} }
        >
          Excluir
        </Button>
        <div>
          { isErrorModalOpened &&
            <Modal 
              open={isErrorModalOpened}
              onClose={closeErrorModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{color: "red", fontWeight: ""}}>
                  Exclusão não permitida!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Este usuário não pode ser excluído pois tem dependências
                </Typography>
              </Box>
            </Modal>
          } 
        </div>
        <div>
          { isSuccessModalOpened &&
            <Modal 
              open={isSuccessModalOpened}
              onClose={closeSuccessModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{color: "red", fontWeight: ""}}>
                  Exclusão realizada com sucesso!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  O usuário foi excluído com sucesso
                </Typography>
              </Box>
            </Modal>
          } 
        </div>
      </div>
     
    </React.Fragment>
    )
};
