import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import ptBR from "date-fns/locale/pt-BR";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import "./index.scss";
import customersService from "../../services/CustomersService";
import format from "date-fns/format";
registerLocale("pt-BR", ptBR);

interface IIndicators {
  [key: string]: string | undefined | number;
  // id: number;
  codigo_bling: number;
  razao_social: string;
  data_cadastro: string;
  total_sale: number;
  SKU: string;
  amount: string;
}

const Indicators = () => {
  const [items, setItems] = useState<IIndicators[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [period, setPeriod] = useState<string>("all");
  const [filteredCustomers, setFilteredCustomers] = useState<IIndicators[]>([]); //copy of "items" list to show a filtered list

  const loadList = (page: number) => {
    setLoading(true);
    customersService
      .getCustomersOrdersSum(period)
      .then((response) => {
        let customers: IIndicators[] = response.data;
        // console.log(customers);
        setItems(customers);
        customers = filterData(customers);
        setFilteredCustomers(customers);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const filterData = (customers: IIndicators[]) => {
    const filteredCustomers = customers.map((item) => {
      const skuSplit = item.SKU.split(",");
      const amountSplit = item.amount.split(",");
      let newSku: string = "";
      for (let i = 0; i < skuSplit.length; i++) {
        newSku += `${skuSplit[i]} (${amountSplit[i]}), `;
      }
      item.SKU = newSku;
      return item;
    });
    return filteredCustomers;
  };

  useEffect(() => {
    loadList(1);
  }, []);

  const exportExcel = () => {
    const csvData: any[] = filteredCustomers;
    const fileName = "LVT";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {
      Sheets: { PedidosClientes: ws },
      SheetNames: ["Pedidos Clientes"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "20px",
      }}
    >
      <Grid item xs={12} container flexDirection={"row"}>
        <Grid item xs={4}>
          <p className="titlePage">Indicadores</p>
        </Grid>
        <Grid item md={8}>
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"center"}
            marginBottom={"5px"}
            xs={12}
          >
            <Grid item xs={4} padding={"5px"}>
              <FormControl fullWidth>
                <InputLabel id="period-selection-id-label">Período</InputLabel>
                <Select
                  id="period-selection-id"
                  labelId="period-selection-id-label"
                  label={"Period"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    setPeriod(value);
                  }}                  
                >
                  <MenuItem value={"all"} selected={(period == "all")} >Todo período</MenuItem>
                  <MenuItem value={"6_month"} selected={(period == "6_month")}>6 meses</MenuItem>
                  <MenuItem value={"1_year"} selected={(period == "1_year")}>1 ano</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item className="form-sections" xs={12}>
            <Grid item xs={5}></Grid>
          </Grid>

          <Grid
            item
            className="form-sections"
            xs={12}
            justifyContent={"center"}
          >
            <Grid item xs={2} marginRight={"70px"}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => loadList(1)}
              >
                Filtrar
              </Button>
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => exportExcel()}
              >
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          height: "calc(100vh - 200px)",
          overflowY: "auto",
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr className="tr_indicators">
              <th>
                <div className="icon">Código Bling</div>
              </th>

              <th>
                <div className="icon">Razão Social</div>
              </th>

              <th>
                <div className="icon">Data de Início</div>
              </th>

              <th>
                <div className="icon">LVT</div>
              </th>

              <th>
                <div className="icon">Top 5 SKU</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={8}>
                  <p>Carregando...</p>
                </td>
              </tr>
            )}
            {filteredCustomers.length > 0 &&
              filteredCustomers.map((item: IIndicators) => (
                <tr key={item.codigo_bling}>
                  <td>
                    <span>{item.codigo_bling}</span>
                  </td>
                  <td>
                    <span>{item.razao_social}</span>
                  </td>
                  <td>
                    <span>
                      {format(new Date(item.data_cadastro), "dd/MM/yyyy")}
                    </span>
                  </td>
                  <td>
                    <span>R$ {String(item.total_sale)?.replace(".", ",")}</span>
                  </td>
                  <td>
                    <span>{item.SKU}</span>
                  </td>
                </tr>
              ))}

            {filteredCustomers.length == 0 && !isLoading && (
              <tr>
                <td colSpan={8}>
                  <p>Nenhum cliente encontrado.</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Indicators;
