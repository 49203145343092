import React, { useState } from "react";

import ModalContext, { ModalContextProps } from "../context/modal.context";
import { MyModal } from "./modal";

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [open, setOpen] = useState(false);
  const [childrenModal, setChildrenModal] = useState<JSX.Element>(<></>);

  const modalContext: ModalContextProps = {
    open,
    setOpen,
    childrenModal,
    setChildrenModal,
  };

  return (
    <ModalContext.Provider value={modalContext}>
      <MyModal
        {...{
          open,
          setOpen,
          children: modalContext.childrenModal,
        }}
      />
      {children}
    </ModalContext.Provider>
  );
};
