import axios from "axios";

let API_URL = process.env.REACT_APP_API_URL;

// Axios Config
const Axios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use(async (config) => {
  let token = await localStorage.getItem("@token");

  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise(async (resolve, reject) => {
      const originalReq = err.config;
      // Inverter o if
      console.log(err.response.status);
      if (
        err.response.status === 401 &&
        err.config &&
        !err.config._retry &&
        !err.config.url.endsWith("login")
      ) {
        originalReq._retry = true;
        let token = await localStorage.getItem("@token");
        let res = axios
          .put(`${API_URL}token/refresh`, { oldToken: token })
          .then((res) => {
            if (res.data.access_token) {
              localStorage.setItem("@token", res.data.access_token);
            }
            originalReq.headers[
              "Authorization"
            ] = `Bearer ${res.data.access_token}`;
            return axios(originalReq);
          })
          .catch((err) => console.log(`Deu erro: ${err}`));
        resolve(res);
      } else {
        reject(err);
      }
    });
  }
);

export default Axios;
