import React, { createContext, Dispatch, SetStateAction } from "react";

export interface ModalContextProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  childrenModal: JSX.Element;
  setChildrenModal: Dispatch<SetStateAction<JSX.Element>>;
}

const ModalContext = createContext<ModalContextProps>({
  open: false,
  setOpen: () => {},
  setChildrenModal: () => {},
  childrenModal: <></>,
});

export default ModalContext;
