import IStore from "../../interfaces/IStore";
import { useAxios } from "../api";
import { StoreFilterRequest, StoresResponse } from "./type.d";

export const useStoreService = () => {
  const { get } = useAxios();

  const getAllStores = async ({
    descricao,
  }: StoreFilterRequest): Promise<IStore[]> => {
    const response = await get<IStore[]>("/loja", {
      params: { descricao },
    });
    const { data } = response;
    return data;
  };

  return {
    getAllStores,
  };
};
