import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { FormFollowUp } from "../FormFollowUp";
import { History } from "../History";
import { TabPanel } from "../TabPanel";
import { FormDetailsInfo } from "../FormDetailsInfo";

export const ModalDetails = ({
  customer,
  setOpen
}: {
  customer: any;
  setOpen: (value: boolean) => void;
}) => {
  const [value, setValue] = useState(0);

  return (
    <Box sx={{ width: "800px", maxHeight: "800px" }}>
      <Grid sx={{ borderBottom: 1, borderColor: "divider" }} xs={12} item>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <Tab
            sx={{
              "&:hover": {
                backgroundColor: "none",
              },
            }}
            label="Detalhes do cliente"
          />
          <Tab
            sx={{
              "&:hover": {
                backgroundColor: "none",
              },
            }}
            label="Novo Follow-up"
          />
          <Tab
            sx={{
              "&:hover": {
                backgroundColor: "none",
              },
            }}
            label="Histórico"
          />
        </Tabs>
      </Grid> 
      <TabPanel value={value} index={0}>
        <FormDetailsInfo customer={customer} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormFollowUp {...{ customer, setOpen }} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <History customerId={customer.id} />
      </TabPanel>
    </Box>
  );
};
