import { useAxios } from "../api";
import { ChangeRoleRequest, UserResponse, UsersResponse } from "./type";

export const useUserService = () => {
  const { get, post } = useAxios();

  const getAll = async (): Promise<UsersResponse> => {
    const response = await get<UsersResponse>("/user/find-all");
    const { data } = response;

    return data;
  };

  const getCurrent = async (): Promise<UserResponse> => {
    const response = await get<UserResponse>("/user/current");
    const { data } = response;

    return data;
  };

  const getAllActive = async (): Promise<UsersResponse> => {
    const response = await get<UsersResponse>("/user/sellers-active");
    const { data } = response;

    return data;
  };

  const getAllActiveBySupervisor = async (): Promise<UsersResponse> => {
    const response = await get<UsersResponse>("/user/sellers-active-by-supervisor");
    const { data } = response;

    return data;
  };

  const postChangeRole = async ({
    userId,
    roleId,
  }: ChangeRoleRequest): Promise<boolean> => {
    const response = await post<boolean>(`/user/${userId}/role`, { roleId });
    const { data } = response;
    return data;
  };

  const getAllSellers = async (): Promise<UsersResponse> => {
    const response = await get<UsersResponse>("/user/sellers");
    const { data } = response;
    return data;
  };

  return {
    getAll,
    getAllActive,
    postChangeRole,
    getAllSellers,
    getCurrent,
    getAllActiveBySupervisor
  };
};
