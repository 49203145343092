import { useContext, useEffect, useMemo, useState } from "react";
import Leads from "./leads";
import {
  CustomerFilterRequest,
  CustomerResponse,
  CustomersResponse,
} from "../../hooks/customer/type";
import { useCustomerService } from "../../hooks/customer";
import { Button } from "react-bootstrap";
import ModalContext from "../../context/modal.context";
import { ModalDetails } from "./components/ModalDetails";

export const LeadsContainer = () => {
  const [items, setItems] = useState<CustomersResponse>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { getAll } = useCustomerService();
  const { setOpen, setChildrenModal, open } = useContext(ModalContext);

  const headers = [
    "Nome da Empresa",
    "Nome do Contato",
    "Telefone/E-mail",
    "Abordagem",
    "Ações",
  ];

  const loadList = async (requestBody: CustomerFilterRequest) => {
    const leads = await getAll({ status: "LEAD_PERDIDO" });
    setItems(leads);
    setLoading(false);
  };

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: ["Nenhum lead encontrado"],
        },
      ];
    }

    const handleOpenModal = (customer: any) => {
      setChildrenModal(<ModalDetails customer={customer} setOpen={setOpen} />);
      setOpen(true);
    };

    return items.map((lead: CustomerResponse) => {
      return {
        values: [
          lead.companyName,
          lead.name,
          lead.contact ? `${lead.contact}` : " ",
          lead.approach,
          <Button onClick={() => handleOpenModal(lead)}>Histórico</Button>,
        ],
      };
    });
  }, [isLoading, items]);

  useEffect(() => {
    loadList({
      nome: "",
      status: "",
      canalDistribuicao: "",
    });
  }, []);

  return (
    <Leads
      {...{
        tableProps: {
          headers,
          rows,
        },
      }}
    />
  );
};
