import { Call, Person, Store } from "@material-ui/icons";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {
  Button,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";

import "./index.scss";
import { useCustomerService } from "../../hooks/customer";
import ModalContext from "../../context/modal.context";
import AuthContext from "../../context/auth";
import ISeller from "../../interfaces/ISeller";
import { useUserService } from "../../hooks/user";

function CadastroLeads() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { create: postCreate } = useCustomerService();

  const { setOpen, setChildrenModal, open } = useContext(ModalContext);
  const { getRole, isSeller, isAdmin, isSupervisor } = useContext(AuthContext)

  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();

  const { getAllActive } = useUserService();

  const formik = useFormik({
    initialValues: {
      nome: "",
      contato: "",
      nomeFantasia: "",
      abordagem: "",
    },

    validationSchema: Yup.object({
      nome: Yup.string().required(
        "É necessário informar o nome da pessoa que irá contatar"
      ),
      contato: Yup.string().required(
        "É necessário informar um telefone ou e-mail"
      ),
    }),

    onSubmit: (values) => {
      create(values);
    },
  });

  const loadSellers = async () => {
    const response = await getAllActive();
    setSellers(response);
  };

  const create = (values: any) => {
    let data = {
      seller: selectedSeller?.id,
      nome: values.nome,
      contato: values.contato,
      nomeFantasia: values.nomeFantasia,
      abordagem: values.abordagem,
    };

    postCreate(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Lead cadastrado com sucesso!",          
          icon: "success",
        }).then((value: any) => {
          setOpen(false)
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const selectSeller = (id: string) => {
    let selectedSeller = sellers.find((item) => item.id == Number(id));
    if (selectedSeller){
      setSelectedSeller(selectedSeller);
    }
  };

  const cancel = () => {
    setOpen(false)
  };

  const inputsProps = {
    item: true,
    xs: 12,
    mb: 2,
  };

  useEffect(() => {
    loadSellers()
  }, [])

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <h2>Cadastrar Lead</h2>

          { (isAdmin() || isSupervisor()) && 
            <Grid {...inputsProps}>            
              <FormControl style={{ width: "300px" }}>
                <InputLabel id="seller-selection-id-label">Vendedor</InputLabel>
                <Select
                  id="seller-selection-id"
                  labelId="seller-selection-id-label"
                  label={"Vendedor"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    selectSeller(value);
                  }}
                >
                  <MenuItem value={""}>Todos</MenuItem>
                  {sellers.map((seller) => (
                    <MenuItem key={String(seller.id)} value={seller.id}>{seller.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>          
            </Grid>
          }

          <Grid {...inputsProps}>
            <TextField
              name="nome"
              type="text"
              placeholder="Nome da pessoa a contatar"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Person />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nome}
            />
            {formik.touched.nome && formik.errors.nome ? (
              <div className="error_msg">{formik.errors.nome}</div>
            ) : null}
          </Grid>

          <Grid {...inputsProps}>
            <TextField
              name="contato"
              type="text"
              placeholder="Telefone ou E-mail"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Call />
                    </Icon>
                    <Icon>
                      <AlternateEmailIcon />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contato}
            />

            {formik.touched.contato && formik.errors.contato ? (
              <div className="error_msg">{formik.errors.contato}</div>
            ) : null}
          </Grid>

          <Grid {...inputsProps}>
            <TextField
              name="nomeFantasia"
              type="text"
              placeholder="Nome da Empresa"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Store />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nomeFantasia}
            />
          </Grid>

          <Grid {...inputsProps}>
            <FormControl className="condicao">
              <InputLabel id="condicao-selection-id-label">
                Tipo de Abordagem
              </InputLabel>
              <Select
                id="condicao-selection-id"
                labelId="condicao-selection-id-label"
                label={"Tipo de Abordagem"}
                onChange={(event) => {
                  const { value } = event.target;
                  formik.setFieldValue("abordagem", value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.abordagem}
              >
                <MenuItem key={"Ativo"} value={"Ativo"}>
                  Ativo
                </MenuItem>
                <MenuItem key={"Passivo"} value={"Passivo"}>
                  Passivo
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            container
            mt={2}
            justifyContent={"space-between"}
          >
            <Grid item xs={5}>
              <Button variant="outlined" type="submit">
                Cadastrar
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button
                sx={{
                  color: "red",
                  borderColor: "red",
                  "&:hover": {
                    color: "red",
                    borderColor: "red",
                    backgroundColor: "rgba(255, 0, 0, 0.04)",
                  },
                }}
                variant="outlined"
                onClick={cancel}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default CadastroLeads;
