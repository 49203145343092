import { useAxios } from "../api";
import {
  CreateFollowupRequest,
  GetPaginatedFollowupsRequest,
  GetPaginatedFollowupsResponse,
} from "./type";

export const useFollowupsService = () => {
  const { get, post } = useAxios();

  const getPaginatedFollowups = async (
    params: GetPaginatedFollowupsRequest
  ): Promise<GetPaginatedFollowupsResponse> => {
    const { data } = await get<GetPaginatedFollowupsResponse>("/followups", {
      params,
    });
    return data;
  };

  const postCreateFollowup = async (bodyRequest: CreateFollowupRequest) => {
    await post("/followups", bodyRequest);
  };

  return { getPaginatedFollowups, postCreateFollowup };
};
