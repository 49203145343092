import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useProductService } from "../../hooks/product";
import { ProductsResponse } from "../../hooks/product/type";
import { useUserService } from "../../hooks/user";
import { UserResponse, UsersResponse } from "../../hooks/user/type";
import { SellerGoals } from "./components/sellerGoals";
import Users from "./sellers";
import { Button } from "react-bootstrap";
import { ModalDetailsSeller } from "./components/ModalDetails";

export const SellersContainer = () => {
  const [items, setItems] = useState<UsersResponse>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductsResponse>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSeller, setSelectedSeller] = useState<any>();

  const { getAllSellers } = useUserService();
  const { getStock } = useProductService();

  const headers = ["ID", "Nome", "Email", "Ações"];

  const loadList = async () => {
    const users = await getAllSellers();
    setItems(users);
  };

  const loadProducts = async () => {
    const response = await getStock();
    setProducts(response);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    await Promise.all([loadList(), loadProducts()]);
    setLoading(false);
  }, [isLoading, items]);

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
          subContent: <div>Subcontent</div>,
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: ["Nenhum vendedor encontrado"],
          subContent: <div>Subcontent</div>,
        },
      ];
    }

    const openGoals = (user: any) => {
      setSelectedSeller(user);
      setShowModal(true);
    };

    return items.map((user: UserResponse, index) => ({
      values: [
        "",
        user.id,
        user.name,
        user.email,
        <Button onClick={() => openGoals(user)}>Metas</Button>,
      ],
    }));
  }, [isLoading, items]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Users rows={rows} headers={headers} />
      {showModal && (
        <ModalDetailsSeller
          seller={selectedSeller}
          products={products}
          close={() => setShowModal(false)}
        />
      )}
    </>
  );
};
