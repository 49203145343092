import { Checkbox, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRoleService } from "../../../../hooks/role";
import { useUserService } from "../../../../hooks/user";
import { ERole } from "../../enum/ERole.enum";
import { UserTableSubContent } from "../../type";

export const RolesSubContent: FC<UserTableSubContent> = ({
  availableRoles,
  userId,
  onChangeRoles
}) => {
  const { postChangeRole } = useUserService();
  const { getAllRolesByUserId } = useRoleService();
  const [selected, setSelected] = useState<Map<number, boolean>>(new Map());

  const getUserRoles = async () => {
    const roles = await getAllRolesByUserId(userId);
    roles.forEach((role) => {
      selected.set(role.id, true);
    });
    setSelected(new Map(selected));
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const roleId = parseInt(e.target.value);
    const result = await postChangeRole({ userId, roleId });
    selected.set(roleId, result);
    setSelected(new Map(selected));
    onChangeRoles(userId)
  };

  return (
    <Grid xs={12} container justifyContent={"center"}>
      {availableRoles.map((role) => (
        <Grid item xs={3} key={role.id}>
          <label
            style={{
              textTransform: "capitalize",
            }}
          >
            {ERole[role.name]}
          </label>
          <Checkbox
            checked={selected.get(role.id) || false}
            onChange={(e, checked) => handleChange(e)}
            name={role.id.toString()}
            value={role.id.toString()}
          />
        </Grid>
      ))}
    </Grid>
  );
};
