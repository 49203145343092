import { Grid, TextField } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CollapsibleTable } from "../../components/tables/collapsibleTable";
import { CollapsibleTableProps } from "../../components/tables/collapsibleTable/type";

interface ILead {
  [key: string]: string | undefined | number;
  id: number;
  nomeEmpresa: string;
  nomeContato: string;
  contato: string;
  abordagem: string;
}

export const Leads: FC<{
  tableProps: CollapsibleTableProps;
}> = ({ tableProps }) => {
  const { headers, rows } = tableProps;

  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={12}>
          <p>Lista de Leads Perdidos</p>
        </Grid>
        <Grid md={8} item>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={"10px"}
            xs={12}
          >
            <Grid item xs={2}>
              {/* <Button
                variant="secondary"
                type="button"
                onClick={() => navigate("/cadastro-leads")}
                style={{alignItems: "flex-start", marginBottom: 10, marginLeft: 500}}>
                Adicionar
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={11}
          style={{
            overflow: "auto",
            maxHeight: "calc(90vh - 200px)",
          }}
        >
          <CollapsibleTable headers={headers} rows={rows} hideFirstColumn />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Leads;
