import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { FC, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { CollapsibleTable } from "../../components/tables/collapsibleTable";
import { CollapsibleTableProps } from "../../components/tables/collapsibleTable/type";
import ISeller from "../../interfaces/ISeller";
import { useUserService } from "../../hooks/user";
import IStore from "../../interfaces/IStore";
import { useStoreService } from "../../hooks/store";

export enum CustomerStatus {
  PROSPECCAO = "Prospecção",
  QUALIFICACAO = "Qualificação",
  APRESENTACAO = "Apresentação",
  FOLLOWUP = "Follow-Up",
  NEGOCIACAO = "Negociação",
  FECHAMENTO = "Fechamento",
}

interface ICustomer {
  [key: string]: string | undefined | number;
  nome: string;
  nomeFantasia: string;
  canalDistribuicao: string;
  fone: number;
  email: string;
  status: CustomerStatus;
}

export const Customers: FC<{
  tableProps: CollapsibleTableProps;
  setFilterName: (value: string) => void;
  setFilterDistribution: (value: string) => void;
  setFilterStatus: (value: string) => void;
  setFilterStore: (value: string) => void;
  setFilterSeller: (value: string) => void;
  handleFilter: () => void;
}> = ({
  tableProps,
  setFilterName,
  setFilterDistribution,
  setFilterStatus,
  setFilterStore,
  setFilterSeller,
  handleFilter,
}) => {
  const { headers, rows } = tableProps;

  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();
  const [stores, setStores] = useState<IStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<IStore>();
  const { getAllStores } = useStoreService();
  const { getAll } = useUserService();

  const selectSeller = (id: string) => {
    setSelectedSeller(sellers.find((item) => item.id == Number(id)));
  };

  const selectStore = (id: string) => {
    setSelectedStore(stores.find((item) => item.id == Number(id)));
  };

  const loadSellers = async () => {
    const response = await getAll();
    setSellers(response);
  };

  const loadStores = async () => {
    const response = await getAllStores({descricao: undefined});
    setStores(response);
  };

  useEffect(() => {
    loadSellers();
    loadStores();
  }, []);

  return (
    <Grid xs={12}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={12}>
          <p>Lista de Clientes</p>
        </Grid>
        <Grid md={8} item>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={"10px"}
            xs={12}
          >
            <Grid item xs={5} mb={2}>
              <TextField
                label={"Nome"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterName(value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={"Canal de Distribuição"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterDistribution(value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={"Status"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterStatus(value);
                }}
              />
            </Grid>
            <Grid item xs={3} mt={2}>
              <FormControl fullWidth>
                <InputLabel id="seller-selection-id-label">Loja</InputLabel>
                <Select
                  id="store-selection-id"
                  labelId="store-selection-id-label"
                  label={"Loja"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    selectStore(value);
                  }}
                >
                  <MenuItem value={""}>Todas</MenuItem>
                  {stores.map((store) => (
                    <MenuItem value={store.id}>{store.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={5}>
              <TextField
                label={"Loja"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterStore(value);
                }}
              />
            </Grid> */}
            <Grid item xs={3} mt={2}>
              <FormControl fullWidth>
                <InputLabel id="seller-selection-id-label">Vendedor</InputLabel>
                <Select
                  id="seller-selection-id"
                  labelId="seller-selection-id-label"
                  label={"Vendedor"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    selectSeller(value);
                  }}
                >
                  <MenuItem value={""}>Todos</MenuItem>
                  {sellers.map((seller) => (
                    <MenuItem value={seller.id}>{seller.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={5}>
              <TextField
                label={"Vendedor"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterSeller(value);
                }}
              />
            </Grid> */}
            <Grid item xs={3}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => handleFilter()}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={11}
          style={{
            overflow: "auto",
            maxHeight: "calc(90vh - 200px)",
          }}
        >
          <CollapsibleTable headers={headers} rows={rows} hideFirstColumn />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Customers;
