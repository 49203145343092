import { Grid, TextField } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import "./index.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import customersService from "../../services/CustomersService";

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomersConversion = () => {
  const [page, setPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(1, "year").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [result, setResult] = useState<any>();

  const loadData = (page: number) => {
    setLoading(true);
    customersService
      .getCustomersConversion(startDate, endDate)
      .then((response) => {
        let data = response.data;
        setResult(data);
        
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadData(1);
  }, []);

  const data = {
    labels: ["Clientes Prospectados", "Clientes em Fechamento ou Pós Venda"],
    datasets: [
      {
        data: result
          ? [
              Number(result[0].clientes_prospectados),
              Number(result[1].clientes_fechamento_posvenda),
              // 12, 19,
            ]
          : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "20px",
      }}
    >
      <Grid item xs={12} container flexDirection={"row"}>
        <Grid item xs={4}>
          <p className="titlePage">Conversão de Clientes</p>
        </Grid>
        <Grid item md={8}>
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"center"}
            marginBottom={"5px"}
            xs={12}
          >
            <Grid item xs={4} padding={"5px"}>
              <TextField
                label={"Período inicial"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={moment(startDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setStartDate(moment(stringDate, "YYYY-MM-DD").toDate());
                }}
              />
            </Grid>

            <Grid item xs={4} padding={"5px"}>
              <TextField
                label={"Período final"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={moment(endDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setEndDate(moment(stringDate, "YYYY-MM-DD").toDate());
                }}
              />
            </Grid>
          </Grid>

          <Grid item className="form-sections" xs={12}>
            <Grid item xs={5}></Grid>
          </Grid>

          <Grid
            item
            className="form-sections"
            xs={12}
            justifyContent={"center"}
          >
            <Grid item xs={3} marginRight={"70px"}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => loadData(1)}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        justifyContent={"center"}
        xs={12}
        sx={{
          height: "calc(100vh - 200px)",
          overflowY: "auto",
        }}
      >
        {result && <Pie data={data} />}
      </Grid>
    </Grid>
  );
};

export default CustomersConversion;
