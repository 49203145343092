import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useCustomerService } from "../../../../hooks/customer";
import { useContext, useEffect } from "react";
import ModalContext from "../../../../context/modal.context";
import swal from "sweetalert";
import { CustomerResponse } from "../../../../hooks/customer/type";
import moment from "moment";

export const FormDetailsInfo = ({ customer }: { customer: CustomerResponse }) => {
  const { getById, update } = useCustomerService();
  const { setOpen, setChildrenModal, open } = useContext(ModalContext);

  const initValue = {
    name: "",
    phone: "",
    creditLimit: "",
    companyName: "",
    lastPurchaseDate: "",
    lastPurchaseValue: 0,
    averageTicket: 0
  };

  const formik = useFormik({
    initialValues: initValue,
    onSubmit: (values, event) => {
      update({
        nome: values.name,
        nomeFantasia: values.companyName,
        credito: Number(values.creditLimit?.replace(",", ".")),
        contato: values.phone,
        id: customer.id,
      });

      swal({
        title: "Cliente atualizado com sucesso!",
        icon: "success",
      }).then((value: any) => {
        setOpen(false);
      });
    },
  });

  const { handleSubmit, handleChange, values } = formik;

  useEffect(() => {
    if (customer) {
      getById(String(customer.id)).then((response) => {
        formik.setValues({
          name: response.name,
          phone: response.contact,
          creditLimit: response.creditLimit?.toString(),
          companyName: response.companyName,
          lastPurchaseDate: (response.lastPurchaseDate) ? moment(response.lastPurchaseDate).format() : "",
          lastPurchaseValue: (response.lastPurchaseValue) ? response.lastPurchaseValue : 0,
          averageTicket: (response.averageTicket) ? response.averageTicket : 0
        });
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        item
        xs={12}
        container
        flexDirection="row"
        justifyContent="space-between"
      >
        <Grid item xs={5} mb={2}>
          <TextField
            id="name"
            name="name"
            label="Nome do contato"
            value={values.name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={5} mb={2}>
          <TextField
            id="phone"
            name="phone"
            label="Telefone"
            value={values.phone}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={5} mb={2}>
          <TextField
            id="companyName"
            name="companyName"
            label="Nome da Empresa"
            value={values.companyName}
            disabled={true}
            fullWidth
          />
        </Grid>

        <Grid item xs={5} mb={2}>
          <TextField
            id="creditLimit"
            name="creditLimit"
            label="Valor Total do Crédito"
            value={values.creditLimit}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={6}
          container
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
        >
          <Grid item xs={5}>
            <Button variant="outlined" type="submit">
              Salvar
            </Button>            
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="outlined"
              sx={{
                color: "red",
                borderColor: "red",
                "&:hover": {
                  color: "red",
                  borderColor: "red",
                  backgroundColor: "rgba(255, 0, 0, 0.04)",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
        >
          <Grid item xs={5}>
            { (values.lastPurchaseDate != null && values.lastPurchaseDate != "") &&
              <>
                <strong>Última compra: </strong>
                <span>Há {moment().diff(moment(values.lastPurchaseDate), 'days')} dias</span>
                <br />
                <strong>Valor: </strong>
                <span>R$ {String(values.lastPurchaseValue)}</span>
              </>
            }
            { (values.lastPurchaseDate == null || values.lastPurchaseDate == "") && 
              <span style={{color: "#c00", fontWeight: "bold"}}>Este cliente ainda não comprou.</span>
            }
          </Grid>
          <Grid item xs={5}>
            { (values.averageTicket != undefined && values.averageTicket > 0) &&
              <>
                <strong>Ticket Médio: </strong>
                <span>R$ {String(Number(values.averageTicket)?.toFixed(2))}</span>            
              </>
            }
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
