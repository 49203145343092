import { useAxios } from "../api";
import { LoginRequest, LoginResponse } from "./type";

export const useLoginService = () => {
  const { post } = useAxios();

  const controllerName = "user";

  const postLogin = async ({ username, password }: LoginRequest) => {
    const { data } = await post<LoginResponse>(`${controllerName}/login`, {
      username,
      password,
    });

    return data;
  };

  return {
    postLogin,
  };
};
