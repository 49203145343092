import "bootstrap/dist/css/bootstrap.min.css";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useGoalService } from "../../hooks/goal";
import Logistics from "./logistics";
import { Badge, BadgeProps, Grid, styled } from "@mui/material";
import { useCustomerService } from "../../hooks/customer";
import { CustomerResponse, MyCustomerFilterRequest } from "../../hooks/customer/type";
import ISeller from "../../interfaces/ISeller";
import moment from "moment";
import ICustomersResponse from "../../interfaces/ICustomersResponse";
import AuthContext from "../../context/auth";
import { useProductService } from "../../hooks/product";
import { LogisticsSummaryResponse } from "./type";

export const LogisticsContainer = () => {
  const [data, setData] = useState<LogisticsSummaryResponse>({} as any);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();
  const { isSeller } = useContext(AuthContext)

  const { getLogisticsSummary } = useProductService();

  const headers = ["SKU", "Produto", "Estoque atual", "Vendas"];

  const loadList = async () => {
    const response: LogisticsSummaryResponse = await getLogisticsSummary();
    console.log(response)
    setData(response);
  };
  
  useEffect(() => {
    setLoading(true);
    loadList()
    setLoading(false);
  }, []);

  if (data){
    return (
      <>
        <p>TOP 20 com Saldo em Estoque</p>
        <Grid item xs={11}>
          <table className="table table-stripped">
            <tr>
            { headers?.map((header) => {
              return <th className="cell-head-customer">{header}</th>
            })}
            </tr>
            { data?.top20ActualStock?.map((row) =>
              <tr>
                <td className="cell-customer">{row.code}</td>
                <td className="cell-customer">{row.description}</td>
                <td className="cell-customer">{row.actual_stock}</td>
                <td className="cell-customer">{row.vendas}</td>
              </tr>
              )
            }
          </table>
        </Grid>

        <p>TOP 20 com Maior Circulação</p>
        <Grid item xs={11}>
          <table className="table table-stripped">
            <tr>
            { headers?.map((header) => {
              return <th className="cell-head-customer">{header}</th>
            })}
            </tr>
            { data?.top20BestSeller?.map((row) =>
              <tr>
                <td className="cell-customer">{row.code}</td>
                <td className="cell-customer">{row.description}</td>
                <td className="cell-customer">{row.actual_stock}</td>
                <td className="cell-customer">{row.vendas}</td>
              </tr>
              )
            }
          </table>
        </Grid>

        <p>TOP 20 com Menor Circulação</p>
        <Grid item xs={11}>
          <table className="table table-stripped">
            <tr>
            { headers?.map((header) => {
              return <th className="cell-head-customer">{header}</th>
            })}
            </tr>
            { data?.top20WorstSeller?.map((row) =>
              <tr>
                <td className="cell-customer">{row.code}</td>
                <td className="cell-customer">{row.description}</td>
                <td className="cell-customer">{row.actual_stock}</td>
                <td className="cell-customer">{row.vendas}</td>
              </tr>
              )
            }
          </table>
        </Grid>

        <p>TOP 20 sem Estoque</p>
        <Grid item xs={11}>
          <table className="table table-stripped">
            <tr>
            { headers?.map((header) => {
              return <th className="cell-head-customer">{header}</th>
            })}
            </tr>
            { data?.top20OutOfStock?.map((row) =>
              <tr>
                <td className="cell-customer">{row.code}</td>
                <td className="cell-customer">{row.description}</td>
                <td className="cell-customer">{row.actual_stock}</td>
                <td className="cell-customer">{row.vendas}</td>
              </tr>
              )
            }
          </table>
        </Grid>

      </>
    )
  }else{
    return <h1>Carregando...</h1>
  }
};
