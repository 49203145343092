import "bootstrap/dist/css/bootstrap.min.css";
import { FC, useContext, useEffect, useState } from "react";
import { CollapsibleTable } from "../../components/tables/collapsibleTable";
import { CollapsibleTableProps } from "../../components/tables/collapsibleTable/type";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ISeller from "../../interfaces/ISeller";
import { useUserService } from "../../hooks/user";
import './index.scss';
import AuthContext from "../../context/auth";
import {
  Button, Col
} from "react-bootstrap";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

interface MyCustomerProps{
  updateList: () => void;
  selectSellerParent: (seller?: ISeller) => void;
}

export const MyCustomers: FC<CollapsibleTableProps & MyCustomerProps> = ({ rows, headers, updateList, selectSellerParent }) => {

  const [sellers, setSellers] = useState<ISeller[]>([]);  
  const { getAllActiveBySupervisor, getCurrent } = useUserService();
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();
  const { isSeller, isSupervisor, isAdmin } = useContext(AuthContext)

  const selectSeller = (id: string) => {    
    let seller = sellers.find((item) => item.id == Number(id))
    selectSellerParent(seller)
  };

  const loadSellers = async () => {
    const response = await getAllActiveBySupervisor();
    setSellers(response);
  };

  useEffect(() => {
    loadSellers().then((result) => {
      console.log(result)
      if (isSeller()){
        getCurrent().then((user: any) => {
          selectSellerParent(user)
        }).catch((error) => {
          console.log(error)
        })
      }
    }).catch((error: any) => {
      console.log(error)
    })
    
  }, []);

  const exportExcel = () => {

    const formattedRows = rows.map((row) => { 
      return {
        "Cliente": row.values[0],
        "Dias sem Comprar": row.values[1].toString().replace(" dias",""),
      }
    });

    const csvData: any[] = formattedRows;
    const fileName = "clientes";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { Clientes: ws }, SheetNames: ["Clientes"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={11}>
          <p>Positivação de Clientes</p>
        </Grid>

        <div className="container-filter-seller">
          <div className="content-filter-left">
          { (isSupervisor() == true || isAdmin() == true) && 
              <FormControl fullWidth>
                <InputLabel id="seller-selection-id-label">Vendedor</InputLabel>
                <Select
                  id="seller-selection-id"
                  labelId="seller-selection-id-label"
                  label={"Vendedor"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    selectSeller(value);
                  }}
                >
                  <MenuItem value={""}>Todos</MenuItem>
                  {sellers.map((seller) => (
                    <MenuItem value={seller.id}>{seller.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          }
          </div>
          <div className="content-filter-right">
            <Button
              variant="secondary"
              type="button"
              onClick={() => exportExcel()}>
              Exportar
            </Button>
          </div>
        </div>

        <Grid item xs={11}>
          <table className="table table-stripped">
            <tr>
            { headers.map((header) => {
              return <th className="cell-head-customer">{header}</th>
            })}
            </tr>
            { rows.map((row) =>
              <tr style={{ backgroundColor: (row.values[2] == "success") ? "#c1ffc8" : "#fcb2b2"}}>
                <td className="cell-customer">{row.values[0]}</td>
                <td className="cell-customer">{row.values[1]}</td>
              </tr>
              )
            }
          </table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyCustomers;
