import { Grid } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { FC } from "react";
import { CollapsibleTable } from "../../components/tables/collapsibleTable";
import { CollapsibleTableProps } from "../../components/tables/collapsibleTable/type";

export const Users: FC<CollapsibleTableProps> = ({ rows, headers }) => {
  return (
    <Grid item xs={12}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Grid item xs={12}>
          <p>Lista de Vendedores</p>
        </Grid>
        <Grid item xs={11}>
          <CollapsibleTable headers={headers} rows={rows} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Users;
