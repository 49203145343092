import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/auth";
import { ModalProvider } from "./providers/modal.provider";
import { AppRoutes } from "./routes";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <AppRoutes />
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
