import "bootstrap/dist/css/bootstrap.min.css";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useGoalService } from "../../hooks/goal";
import MyCustomers from "./mycustomers";
import { Badge, BadgeProps, styled } from "@mui/material";
import { useCustomerService } from "../../hooks/customer";
import { CustomerResponse, MyCustomerFilterRequest } from "../../hooks/customer/type";
import ISeller from "../../interfaces/ISeller";
import moment from "moment";
import ICustomersResponse from "../../interfaces/ICustomersResponse";
import AuthContext from "../../context/auth";

export const MyCustomersContainer = () => {
  const [items, setItems] = useState<CustomerResponse[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();
  const { isSeller } = useContext(AuthContext)

  const { getMyCustomersWithPurchaseBySeller } = useCustomerService();

  const headers = ["Cliente", "Dias sem comprar"];

  const loadList = async (params: MyCustomerFilterRequest) => {
    const response: ICustomersResponse = await getMyCustomersWithPurchaseBySeller(params.seller);
    setItems(response.customers);
  };

  const selectSeller = (seller?: ISeller) => {
    setSelectedSeller(seller)
  }

  useEffect(() => {
    if (selectedSeller){
      fetchData()
    }
  }, [selectedSeller])

  const fetchData = useCallback(async () => {
    if (selectedSeller){
      setLoading(true);
      await Promise.all([loadList({ seller: selectedSeller.id })]);
      setLoading(false);
    }
  }, [isLoading, items, selectedSeller]);

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: [(isSeller()) ? "Carregando" : "Selecione um vendedor"],
        },
      ];
    }

    return items.map((item: CustomerResponse) => {
      return {
        values: [
          item.companyName,
          (item.lastPurchaseDate) ? moment().diff(moment(item.lastPurchaseDate), 'days') + ' dias' : "Nunca comprou",
          (item.lastPurchaseDate) ? (moment().diff(moment(item.lastPurchaseDate), 'days') > 40 ? "error" : "success") : "error"
        ],
      };
    });
  }, [isLoading, items]);

  return <MyCustomers rows={rows} headers={headers} updateList={fetchData} selectSellerParent={selectSeller} />;
};
