import { useAxios } from "../api";
import { Roles } from "./type";

export const useRoleService = () => {
  const { get } = useAxios();

  const getAll = async (): Promise<Roles> => {
    const { data } = await get<Roles>("/roles");
    return data;
  };

  const getAllRolesByUserId = async (userId: number): Promise<Roles> => {
    const { data } = await get<Roles>(`/roles/${userId}`);
    return data;
  };

  return {
    getAll,
    getAllRolesByUserId,
  };
};
