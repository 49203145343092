import { Button, InputAdornment, TextField } from "@material-ui/core";
import { Lock, Person } from "@material-ui/icons";
import { Icon } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Loading from "../../components/loading";
import AuthContext from "../../context/auth";
import { useLoginService } from "../../hooks/login";
import SubmitForm from "./SubmitForm";
import "./index.scss";
import { AppRoutesEnum } from "../../routes/app.routes";

const Login = () => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingToken, setLoadingToken] = useState(false);

  const { postLogin } = useLoginService();

  const { singIn } = useContext(AuthContext);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Endereço de e-mail inválido")
        .required("Preencha o e-mail"),
      password: Yup.string().min(5).required("Senha inválida"),
    }),

    onSubmit: (values) => {
      handleSubmitLogin(values);
    },
  });

  const handleSubmitLogin = (values: any) => {
    setLoading(true);

    let data = {
      username: values.email,
      password: values.password,
    };

    postLogin(data)
      .then((response) => {
        setLoading(false);
        singIn({ token: response.access_token, role: response.roles?.map((role: any) => role.name)?.join(",") });
        navigate(AppRoutesEnum.PRODUCTS);
      })
      .catch((error) => {
        alert("Usuário e/ou senha inválida");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bodylogin">
      <div className="container1">
        <div
          className={
            !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
          }
        >
          {isSubmitSuccess ? (
            <SubmitForm />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              {isLoadingToken && <text> Só um minutinho...</text>}

              {!isLoadingToken && (
                <>
                  <div className="logoJP">
                    <img src="./logo192.png" alt="logo da JP2Auto" />
                  </div>

                  <h2>Acesse sua conta</h2>

                  <TextField
                    name="email"
                    type="text"
                    placeholder="Email"
                    className="textField"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <Person />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div className="error_msg">{formik.errors.email}</div>
                  ) : null}

                  <TextField
                    name="password"
                    type="password"
                    placeholder="Senha"
                    className="textField"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>
                            <Lock />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error_msg">{formik.errors.password}</div>
                  ) : null}

                  {isLoading && <Loading />}

                  {!isLoading && (
                    <Button
                      className="button-confirm"
                      variant="contained"
                      type="submit"
                    >
                      Entrar
                    </Button>
                  )}

                  <div className="altern"></div>

                  {/* <Button className="button-action" variant="contained" onClick={signUp}>
            Cadastre-se agora
            </Button>
                         */}
                </>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
