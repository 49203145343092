import { Route, Routes } from "react-router-dom";
import AppLayout from "../components/layout/AppLayout";
import Cadastro from "../pages/Cadastro";
import Customers from "../pages/Customers";
import GoalsContainer from "../pages/Goals";
import Leads from "../pages/LostLeads";
import Login from "../pages/Login";
import ProductList from "../pages/ProductList";
import ProductStock from "../pages/ProductStock";
import Sellers from "../pages/Sellers";
import Users from "../pages/Users";
import { AppRoutesEnum } from "./app.routes";
import { NewSalesFunnel } from "../pages/NewSalesFunnel";
import Indicators from "../pages/Indicators";
import CustomersConversion from "../pages/CustomersConversion";
import StorePerformance from "../pages/StorePerformance";
import MonthRank from "../pages/MonthRank";
import SellerPerformance from "../pages/SellerPerformance";
import { MyCustomersContainer } from "../pages/MyCustomers/mycustomers.container";
import { LogisticsContainer } from "../pages/Logistics/logistics.container";

export const AppRoutes = () => {
  const loggedRoutes = [
    {
      path: AppRoutesEnum.PRODUCT_STOCK,
      element: <ProductStock />,
    },
    {
      path: AppRoutesEnum.USERS,
      element: <Users />,
    },
    {
      path: AppRoutesEnum.PRODUCTS,
      element: <ProductList />,
    },
    {
      path: AppRoutesEnum.CUSTOMERS,
      element: <Customers />,
    },
    {
      path: AppRoutesEnum.LOST_LEADS,
      element: <Leads />,
    },
    {
      path: AppRoutesEnum.CUSTOMERS_CONVERSION,
      element: <CustomersConversion />,
    },
    {
      path: AppRoutesEnum.INDICATORS,
      element: <Indicators />,
    },
    {
      path: AppRoutesEnum.SELLERS,
      element: <Sellers />,
    },
    {
      path: AppRoutesEnum.USER_FORM,
      element: <Cadastro />,
    },
    {
      path: AppRoutesEnum.STORE_PERFORMANCE,
      element: <StorePerformance />,
    },
    {
      path: AppRoutesEnum.MY_CUSTOMERS,
      element: <MyCustomersContainer />,
    },
    {
      path: AppRoutesEnum.SELLERS_GOALS,
      element: <GoalsContainer />,
    },
    {
      path: AppRoutesEnum.SELLER_PERFORMANCE,
      element: <SellerPerformance />,
    },
    {
      path: AppRoutesEnum.SALES_FUNNEL,
      element: <NewSalesFunnel />,
    },
    {
      path: AppRoutesEnum.LOGISTICS,
      element: <LogisticsContainer />,
    },
  ];

  const notLoggedRoutes = [
    {
      path: AppRoutesEnum.LOGIN,
      element: <Login />,
    },
    {
      path: AppRoutesEnum.MONTH_RANK,
      element: <MonthRank />,
    },
  ];

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {loggedRoutes.map((route, index) => (
          <Route key={`logged-${index}`} {...route} />
        ))}
      </Route>
      <Route path="/">
        {notLoggedRoutes.map((route, index) => (
          <Route key={`logged-${index}`} {...route} />
        ))}
      </Route>
    </Routes>
  );
};
