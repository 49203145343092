export default class UtilDate {
  static BE_FORMAT_DATE = "yyyy-mm-dd";
  static DISPLAY_FORMAT_DATE = "dd/mm/yyyy";

  static stringToDMY(stringDate: string) {
    const date = new Date(stringDate);
    if (date) {
      return (
        date.getDate()?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
  }

  static dateToYMD(date: Date) {
    if (date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate()?.toString().padStart(2, "0")
      );
    } else {
      return "";
    }
  }

  static stringToDMYHM(stringDate: string) {
    const date = new Date(stringDate);
    if (date) {
      return (
        date.getDate()?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " às " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    } else {
      return "";
    }
  }

  static dateToDMY(date: Date) {
    if (date) {
      return (
        date.getDate()?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear()
      );
    } else {
      return "";
    }
  }

  static dateToDMYHM(date: Date) {
    if (date) {
      return (
        date.getDate()?.toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear() +
        " às " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    } else {
      return "";
    }
  }
}
