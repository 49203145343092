import "bootstrap/dist/css/bootstrap.min.css";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGoalService } from "../../hooks/goal";
import { GoalFilter, GoalResponse, GoalsResponse } from "../../hooks/goal/type";
import { Goals } from "./goals";
import { Badge, BadgeProps, styled } from "@mui/material";

export const GoalsContainer = () => {
  const [items, setItems] = useState<GoalsResponse>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const { getGoalsByFilter } = useGoalService();

  const headers = ["Produto", "Tipo", "Data Inicio", "Data Final", "Valor"];

  const loadList = async (params: GoalFilter) => {
    let response = await getGoalsByFilter(params);
    setItems(response);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    await Promise.all([loadList({})]);
    setLoading(false);
  }, [isLoading, items]);

  const BadgeType = ({ type }: { type: string }) => {
    if (type === "META_GERAL") {
      return <Badge color="primary" badgeContent="Geral" />;
    } else {
      return <Badge color="success" badgeContent="Produto" />;
    }
  };

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: ["Sem metas cadastradas"],
        },
      ];
    }

    return items.map((item: GoalResponse) => {
      return {
        values: [
          item.product ? item.product.name : "Geral",
          <BadgeType type={item.type} />,
          format(new Date(item.startDate), "dd/MM/yyyy"),
          format(new Date(item.endDate), "dd/MM/yyyy"),
          item.value,
        ],
      };
    });
  }, [isLoading, items]);

  useEffect(() => {
    fetchData();
  }, []);

  return <Goals rows={rows} headers={headers} />;
};
