import { Grid, Modal } from "@mui/material";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export const MyModal = ({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: JSX.Element;
}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid item sx={{ ...modalStyle }}>
        {children}
      </Grid>
    </Modal>
  );
};
