import { Grid, TextField } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { parse } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import * as XLSX from "xlsx";
import IImportData from "../../interfaces/IImportData";
import ISortType from "../../interfaces/ISortType";
import productService from "../../services/ProductService";
import "../../shared/_global.scss";
import UtilDate from "../../utils/util.date";
import "./index.scss";
registerLocale("pt-BR", ptBR);

interface IProduct {
  [key: string]: string | undefined | number | Date;
  id: number;
  codigo: string;
  descricao: string;
  tipo: string;
  ativo: string;
  unidade: string;
  preco: number;
  precoCusto: number;
  estoqueMinimo: number;
  estoqueMaximo: number;
  estoqueAtual: number;
  categoria: string;
  dataInclusao: Date;
  dataAlteracao: Date;
  estoqueIdeal: number;
  vendas: number;
  indiceEstoque: number;
  lucroUnitario: number;
  represadoTotal: number;
  diasSemVendas: number;
  previsaoVendas30dias: number;
  previsaoVendas90dias: number;
}

const ProductStock = () => {
  const [items, setItems] = useState<IProduct[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(30, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [orderBy, setOrderBy] = useState<string>("indiceEstoque");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [filterSku, setFilterSku] = useState<string>("");
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([]); //copy of "items" list to show a filtered list

  const sortTypes: ISortType[] = [
    { column: "codigo", type: "string" },
    { column: "descricao", type: "string" },
    { column: "categoria", type: "string" },
    { column: "estoqueMinimo", type: "number" },
    { column: "estoqueMaximo", type: "number" },
    { column: "estoqueAtual", type: "number" },
    { column: "estoqueIdeal", type: "number" },
    { column: "vendas", type: "number" },
    { column: "lucroUnitario", type: "number" },
    { column: "represadoTotal", type: "number" },
    { column: "diasSemVendas", type: "number" },
    { column: "previsaoVendas30dias", type: "number" },
    { column: "previsaoVendas90dias", type: "number" },
    
  ];

  const loadList = (page: number) => {
    setLoading(true);
    productService
      .getSummaryStock(startDate, endDate)
      .then((response) => {
        console.log(response.data)
        let products: IProduct[] = response.data;

        products = products.map((product) => {
          product.indiceEstoque = getIndexStock(product);
          return product;
        });

        products = sortProducts(products, orderBy);

        setItems(products);

        products = filterList(products);
        setFilteredProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getLastUpdate = () => {
    productService
      .getLastUpdate()
      .then((response) => {
        let data: IImportData = response.data;
        let momentDate = moment(data.dataHora, "YYYY-MM-DDTHH:mm:ss").toDate();
        data.dataHora = momentDate;
        setLastUpdate(data.dataHora);
      })
      .catch((error) => {});
  };

  const getIndexStock = (item: IProduct) => {
    if (item.estoqueMinimo > 0) {
      return (
        Math.round((item.estoqueAtual / item.estoqueMinimo) * 100 * 100) / 100
      );
    } else {
      return 0;
    }
  };

  const getColor = (item: IProduct) => {
    if (getIndexStock(item) < 65.99) {
      return "#FCB2B2";
    } else if (getIndexStock(item) < 99) {
      return "#FFC98A";
    } else if (getIndexStock(item) < 120) {
      return "#FFECA8";
    } else {
      return "#C1FFC8";
    }
  };

  useEffect(() => {
    loadList(1);
    getLastUpdate();
  }, []);

  const filterList = (products: IProduct[]) => {
    if (filterSku != "") {
      products = products.filter((product) => {
        return (
          product.codigo.toLowerCase().indexOf(filterSku.toLowerCase()) >= 0
        );
      });
    }

    if (filterCategory != "") {
      products = products.filter((product) => {
        return (
          product.categoria
            .toLowerCase()
            .indexOf(filterCategory.toLowerCase()) >= 0
        );
      });
    }
    return products;
  };

  useEffect(() => {
    let products = items;

    products = filterList(products);

    setFilteredProducts(products);
  }, [filterSku, filterCategory]);

  const handleHeadClick = (column: string) => {
    let direction = sortDirection;
    if (orderBy == column) {
      direction = sortDirection == "ASC" ? "DESC" : "ASC";
    } else {
      direction = "DESC";
    }
    setOrderBy(column);
    setSortDirection(direction);

    let products = sortProducts(filteredProducts, column);
    setFilteredProducts(products);
  };

  const sortProducts = (products: IProduct[], column: string) => {
    //ordena a lista pelo atributo selecionado para ordenação
    products = products.sort((a, b): any => {
      let value1: any = a[column];
      let value2: any = b[column];

      const sortType: ISortType | undefined = sortTypes.find(
        (item) => item.column == column
      );
      if (sortType?.type == "string") {
        if (sortDirection == "DESC") {
          return value1.localeCompare(value2);
        } else {
          return value2.localeCompare(value1);
        }
      } else {
        if (sortDirection == "DESC") {
          if (Number(value1) < Number(value2)) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (Number(value1) > Number(value2)) {
            return 1;
          } else {
            return -1;
          }
        }
      }
    });
    return products;
  };

  const exportExcel = () => {
    const csvData: any[] = filteredProducts;
    const fileName = "gestao_estoque";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {
      Sheets: { "Gestão de Estoque": ws },
      SheetNames: ["Gestão de Estoque"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Grid
      xs={12}
      sx={{
        padding: "10px",
      }}
    >
      <Grid xs={12} container flexDirection={"row"}>
        <Grid xs={6} className="titlePage">
          <p>Estoque de Produtos</p>
          {lastUpdate && (
            <p>
              <>Atualizado em {UtilDate.dateToDMYHM(lastUpdate)}</>
            </p>
          )}
        </Grid>
        <Grid md={6}>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={"10px"}
            xs={12}
          >
            <Grid item xs={5}>
              <TextField
                label={"Período inicial"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setStartDate(parse(stringDate, 'yyyy-MM-dd', new Date()));
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                label={"Período final"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setEndDate(parse(stringDate, 'yyyy-MM-dd', new Date()));
                }}
              />
            </Grid>
          </Grid>

          <Grid className="form-sections" xs={12}>
            <Grid item xs={5}>
              <TextField
                label={"SKU"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFilterSku(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={"Categoria"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFilterCategory(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid className="form-sections" md={5}>
            <Grid item xs={5}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => loadList(1)}
              >
                Filtrar
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => exportExcel()}
              >
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sx={{
          height: "calc(90vh - 200px)",
          overflowY: "auto",
        }}
      >
        <Table striped bordered hover className="flatlist">
          <thead>
            <tr>
              <th>
                <a
                  onClick={() => handleHeadClick("descricao")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Produto
                  {orderBy == "descricao" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("codigo")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  SKU
                  {orderBy == "codigo" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("categoria")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Categoria
                  {orderBy == "categoria" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("estoqueAtual")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Estoque Atual
                  {orderBy == "estoqueAtual" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("estoqueMinimo")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Estoque Ideal
                  {orderBy == "estoqueMinimo" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("indiceEstoque")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Índice
                  {orderBy == "indiceEstoque" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("vendas")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Vendas
                  {orderBy == "vendas" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("lucroUnitario")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Lucro Unitário
                  {orderBy == "lucroUnitario" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("represadoTotal")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Represado Total
                  {orderBy == "represadoTotal" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("diasSemVendas")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Dias sem Vendas
                  {orderBy == "diasSemVendas" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("diasSemVendas")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Previsão 30 dias
                  {orderBy == "previsaoVendas30dias" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
              <th>
                <a
                  onClick={() => handleHeadClick("diasSemVendas")}
                  href="javascript: void(0)"
                  className="clicavel"
                >
                  Previsão 90 dias
                  {orderBy == "previsaoVendas90dias" && (
                    <>
                      {sortDirection == "ASC" && <BsFillCaretDownFill />}
                      {sortDirection == "DESC" && <BsFillCaretUpFill />}
                    </>
                  )}
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((item: any) => (
              <tr
                style={{ backgroundColor: getColor(item) }}
                id={String(item.id)}
              >
                <td>{item.descricao}</td>
                <td>{item.codigo}</td>
                <td>{item.categoria}</td>
                <td>{item.estoqueAtual}</td>
                <td>{item.estoqueMinimo}</td>
                <td>{item.indiceEstoque}%</td>
                <td>{item.vendas}</td>
                <td>R$ {item.lucroUnitario}</td>
                <td>R$ {item.represadoTotal}</td>
                <td>{item.diasSemVendas}</td>
                <td>{item.previsaoVendas30dias}</td>
                <td>{item.previsaoVendas90dias}</td>
              </tr>
            ))}

            {filteredProducts.length == 0 && !isLoading && (
              <tr>
                <td colSpan={12}>
                  <p>Nenhum produto encontrado.</p>
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan={12}>
                  <p>Carregando...</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default ProductStock;
