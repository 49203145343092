import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { SideBarContainer } from "../sidebar/sidebar.container";

const AppLayout = () => {
  const OutLetMemo = useMemo(() => <Outlet />, []);
  return (
    <Grid
      sx={{
        height: "100vh",
      }}
      item
      container
      flexDirection={"row"}
      xs={12}
    >
      <Grid item xs={2}>
        <SideBarContainer />
      </Grid>
      <Grid
        item
        container
        xs={10}
        sx={{
          height: "100vh",
          overflow: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            backgroundColor: "#fff",
          }}
        >
          {OutLetMemo}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppLayout;
