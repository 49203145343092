import React, { createContext, useEffect } from "react";

export interface SingInParams {
  token: string;
  role: string;
}
interface AuthContextData {
  isSigned: () => boolean;
  isSeller: () => boolean;
  isAdmin: () => boolean;
  isSupervisor: () => boolean;
  isLogistics: () => boolean;
  getToken: () => string | null;
  getRole: () => string | null;
  singIn: (params: SingInParams) => void;
  singOut: () => void;
}

interface ProviderProps {
  children: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<ProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const singIn = ({ role, token }: SingInParams) => {
    localStorage.setItem("@token", token);
    localStorage.setItem("@role", role);
  };

  const singOut = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("@role");
  };

  const getToken = () => localStorage.getItem("@token");
  const getRole = () => localStorage.getItem("@role");
  const isSigned = () => !!localStorage.getItem("@token");

  const isSeller = () => {
    let role = getRole()
    if (role){
      return (role.indexOf("seller") >= 0)
    }else{
      return false
    }
  }

  const isAdmin = () => {
    let role = getRole()
    if (role){
      return (role.indexOf("admin") >= 0)
    }else{
      return false
    }
  }

  const isSupervisor = () => {
    let role = getRole()
    if (role){
      return (role.indexOf("supervisor") >= 0)
    }else{
      return false
    }
  }

  const isLogistics = () => {
    let role = getRole()
    if (role){
      return (role.indexOf("logistics") >= 0)
    }else{
      return false
    }
  }

  return (
    <AuthContext.Provider
      value={{ singIn, singOut, getToken, getRole, isSigned, isSeller, isAdmin, isSupervisor, isLogistics }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
