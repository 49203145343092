import { ModeEdit, PermPhoneMsg } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ModalContext from "../../context/modal.context";
import { useCustomerService } from "../../hooks/customer";
import {
  CustomerFilterRequest,
  CustomerResponse,
  CustomersResponse,
} from "../../hooks/customer/type";
import Customers from "./customers";

export const CustomersContainer = () => {
  const [items, setItems] = useState<CustomersResponse>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { getAll: getAllCustomers } = useCustomerService();
  const { setOpen, setChildrenModal } = useContext(ModalContext);
  const [statuses, setStatuses] = useState<string[]>([
    "Prospecção",
    "Qualificação",
    "Apresentação",
    "Follow-Up",
    "Negociação",
    "Fechamento",
  ]);
  const [filterName, setFilterName] = useState<string>("");
  const [filterDistribution, setFilterDistribution] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterStore, setFilterStore] = useState<string>("");
  const [filterSeller, setFilterSeller] = useState<string>("");

  const headers = [
    "Nome",
    "Nome Fantasia",
    "Canal de Distribuição",
    "Telefone",
    "Email",
    "Status",
    "Ações",
  ];

  const handleFilter = () => {
    fetchData();
  };

  const handleOpenModalChangeStatus = (customer: any) => {
    setChildrenModal(
      <Box sx={{ width: "400px", height: "100%" }}>
        <Grid item xs={8} container justifyContent={"start"}>
          <Grid item xs={12}>
            <h1>{customer.nomeFantasia}</h1>
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <InputLabel id="loja-selection-id-label">Status</InputLabel>
              <Select
                id="loja-selection-id"
                labelId="loja-selection-id-label"
                label={"Status"}
                onChange={(event: SelectChangeEvent<string>) => {
                  const { value } = event.target;
                  // selectStore(value);
                }}
              >
                {statuses.map((status) => (
                  <MenuItem value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} mt={3}>
            <Button variant="outlined">Salvar</Button>
          </Grid>
        </Grid>
      </Box>
    );
    setOpen(true);
  };

  const handleOpenModalContactDescription = (customer: CustomerResponse) => {
    setChildrenModal(
      <Box sx={{ width: "500px" }}>
        <Grid item xs={12} container direction={"row"}>
          <Grid item xs={12}>
            <h1>{customer.companyName}</h1>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descreva o que foi combinado no contato com o cliente"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={9} mt={3}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              type={"date"}
              label={"Data do próximo contato"}
            />
          </Grid>
          <Grid item xs={4} mt={3}>
            <Button variant="outlined">Salvar</Button>
          </Grid>
        </Grid>
      </Box>
    );
    setOpen(true);
  };

  const loadList = async (requestBody: CustomerFilterRequest) => {
    const customers = await getAllCustomers({ ...requestBody });
    setItems(customers);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    loadList({
      nome: filterName,
      status: filterStatus,
      canalDistribuicao: filterDistribution,
      loja: filterStore,
      vendedor: filterSeller,
    });
    setLoading(false);
  }, [
    isLoading,
    items,
    filterName,
    filterDistribution,
    filterStatus,
    filterStore,
    filterSeller,
  ]);

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: ["Nenhum cliente encontrado"],
        },
      ];
    }

    return items.map((customer: CustomerResponse) => {
      return {
        values: [
          customer.name,
          customer.companyName,
          customer.distributionChannel,
          customer.phone,
          customer.email,
          customer.status,
          <>
            <IconButton
              key={customer.id}
              sx={{ height: 30, width: 30 }}
              onClick={() => {
                handleOpenModalChangeStatus(customer);
              }}
            >
              <ModeEdit />
            </IconButton>
            <IconButton
              key={customer.id}
              sx={{ height: 30, width: 30 }}
              onClick={() => {
                handleOpenModalContactDescription(customer);
              }}
            >
              <PermPhoneMsg />
            </IconButton>
          </>,
        ],
      };
    });
  }, [isLoading, items]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Customers
      {...{
        tableProps: {
          headers,
          rows,
        },
        setFilterName,
        setFilterDistribution,
        setFilterStatus,
        setFilterStore,
        setFilterSeller,
        handleFilter,
      }}
    />
  );
};
