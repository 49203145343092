import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { Row } from "./components/row";
import { CollapsibleTableProps } from "./type";

export const CollapsibleTable: FC<CollapsibleTableProps> = ({
  headers,
  rows,
  hideFirstColumn,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {!hideFirstColumn && <TableCell />}
            {headers.map((header, id) => (
              <TableCell key={id} align={"center"}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, id) => (
            <Row key={id} {...row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
