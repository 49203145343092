import {
  Email,
  Lock,
  Person,
  Store,
  SupervisorAccount,
} from "@material-ui/icons";
import {
  Button,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import IStore from "../../interfaces/IStore";
import storeService from "../../services/StoreService";
import usersService from "../../services/UsersService";
import "./index.scss";
import { AppRoutesEnum } from "../../routes/app.routes";

function Cadastro() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [stores, setStores] = useState<IStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<IStore>();

  const navigate = useNavigate();

  const selectStore = (id: string) => {
    setSelectedStore(stores.find((item) => item.id == Number(id)));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      confPassword: "",
      store: "",
      userType: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-mail inválido")
        .required("É necessário preencher o campo e-mail"),
      password: Yup.string()
        .min(6, "Senha deve conter no mínimo 6 caracteres")
        .required("É necessário preencher o campo senha"),
      confPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Senha não corresponde")
        .required("É necessário confirmar a senha"),
      name: Yup.string().required("É necessário preencher o campo nome"),
      store: Yup.string().required("É necessário selecionar uma loja"),
      userType: Yup.string().required(
        "É necessário selecionar um tipo de usuário"
      ),
    }),

    onSubmit: (values) => {
      create(values);
    },
  });

  const create = (values: any) => {
    let data = {
      name: values.name,
      email: values.email,
      password: values.password,
      store: values.store,
      userType: values.userType,
    };
    
    usersService
      .create(data)
      .then((response) => {
        setLoading(false);
        swal({
          title: "Cadastro realizado com sucesso!",
          text: "Você será direcionado a lista de usuários",
          icon: "success",
        }).then((value: any) => {
          navigate(AppRoutesEnum.USERS);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const cancel = () => {
    navigate(AppRoutesEnum.USERS);
  };

  useEffect(() => {
    loadStores();
  }, []);

  const loadStores = () => {
    storeService
      .getAll()
      .then((response) => {
        let data: IStore[] = response.data;
        data = data.filter((store) => [203404783, 204308099, 204593611].includes(store.id) ) //apenas LOJAS DE APARECIDA, SJC e CANAL LOJISTA
        setStores(data);
      })
      .catch((error) => {});
  };

  const inputsProps = {
    item: true,
    xs: 12,
    mb: 2,
  };
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <h2>Cadastrar Usuário</h2>

          <Grid {...inputsProps}>
            <TextField
              name="name"
              type="text"
              placeholder="Nome completo"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Person />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error_msg">{formik.errors.name}</div>
            ) : null}
          </Grid>

          <Grid {...inputsProps}>
            <TextField
              name="email"
              type="text"
              placeholder="E-mail"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Email />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="error_msg">{formik.errors.email}</div>
            ) : null}
          </Grid>

          <Grid {...inputsProps}>
            <TextField
              name="password"
              type="password"
              placeholder="Senha"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Lock />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error_msg">{formik.errors.password}</div>
            ) : null}
          </Grid>

          <Grid {...inputsProps}>
            <TextField
              name="confPassword"
              type="password"
              placeholder="Confirmar senha"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>
                      <Lock />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confPassword}
            />
            {formik.touched.confPassword && formik.errors.confPassword ? (
              <div className="error_msg">{formik.errors.confPassword}</div>
            ) : null}
          </Grid>

          <Grid {...inputsProps}>
            <FormControl fullWidth>
              <InputLabel id="loja-selection-id-label">
                Selecione uma loja
              </InputLabel>
              <Select
                id="loja-selection-id"
                labelId="loja-selection-id-label"
                label={"Selecione uma loja"}
                onChange={(event: SelectChangeEvent<string>) => {
                  const { value } = event.target;
                  formik.setFieldValue("store", value);
                  selectStore(value);
                }}
              >
                {stores.map((store) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.store && formik.errors.store ? (
                <div className="error_msg">{formik.errors.store}</div>
              ) : null}
            </FormControl>
          </Grid>

          <Grid {...inputsProps}>
            <FormControl className="condicao">
              <InputLabel id="condicao-selection-id-label">
                Selecione o tipo do usuário
              </InputLabel>
              <Select
                id="condicao-selection-id"
                labelId="condicao-selection-id-label"
                label={"Selecione o tipo do usuário"}
                onChange={(event) => {
                  const { value } = event.target;
                  formik.setFieldValue("userType", value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.userType}
              >
                <MenuItem key={"seller"} value={"seller"}>
                  Vendedor
                </MenuItem>
                <MenuItem key={"supervisor"} value={"supervisor"}>
                  Supervisor
                </MenuItem>
              </Select>
              {formik.touched.userType && formik.errors.userType ? (
                <div className="error_msg">{formik.errors.userType}</div>
              ) : null}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            container
            mt={2}
            justifyContent={"space-between"}
          >
            <Grid item xs={5}>
              <Button className="button-confirm" type="submit">
                Cadastrar
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button className="button-cancel" onClick={cancel}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Cadastro;
