import { Button, Grid } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useGoalService } from "../../../../hooks/goal";
import { GoalFormInput } from "../../../../hooks/goal/type";
import { UserTableSubContent } from "../../type";
import { FormGoal } from "./formGoal";
import { format, parse, parseISO } from "date-fns";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

export const SellerGoals: FC<UserTableSubContent> = ({
  sellerId,
  products,
  close,
  handleDelete,
}) => {
  const [goals, setGoals] = useState<GoalFormInput[]>([]);

  const { getGoalsBySellerId, delGoalById, postCreateGoal, putUpdateGoalById } =
    useGoalService();

  const createEmptyGoal = (): GoalFormInput => {
    return {
      startDate: "",
      endDate: "",
      value: "",
      idProduct: "",
    };
  };

  const loadGoals = useCallback(async () => {
    const goals = await getGoalsBySellerId(sellerId);

    let formatedGoals: GoalFormInput[] = goals.map((goal) => ({
      id: goal.id,
      endDate: goal.endDate,
      startDate: goal.startDate,
      value: goal.value,
      idProduct: goal.product ? goal.product.id.toString() : "",
    }));

    formatedGoals.push(createEmptyGoal());

    setGoals(formatedGoals);
  }, [goals]);

  useEffect(() => {
    loadGoals();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <strong>Início</strong>
        </Col>
        <Col>
          <strong>Fim</strong>
        </Col>
        <Col>
          <strong>Meta</strong>
        </Col>
        <Col>
          <strong>Produto</strong>
        </Col>
        <Col>
          <strong>Ações</strong>
        </Col>
      </Row>
      {goals
        .filter((item) => item.startDate != "")
        .map((goal) => (
          <Row key={goal.id} style={{ marginTop: 20 }}>
            <Col>
              {moment(goal.startDate.toString(), "YYYY-MM-DD").format("DD/MM/YYYY")}
            </Col>
            <Col>
            {moment(goal.endDate.toString(), "YYYY-MM-DD").format("DD/MM/YYYY")}
            </Col>
            <Col>{goal.idProduct ? goal.value : "R$ " + goal.value}</Col>
            <Col>
              {
                products.find((product) => product.id == goal.idProduct)
                  ?.description
              }
            </Col>
            <Col>
              <Button
                type={"button"}
                onClick={() => handleDelete(goal.id)}
                variant="outlined"
                fullWidth
              >
                Excluir
              </Button>
            </Col>
          </Row>
        ))}
      <Row>
        <Col>
          <Button
            type={"button"}
            onClick={close}
            variant="outlined"
            fullWidth
            style={{ marginTop: 20 }}
          >
            Fechar
          </Button>
        </Col>
      </Row>
    </>
  );
};
