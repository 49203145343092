export enum AppRoutesEnum {
  USERS = "/users",
  USER_FORM = "/users/new",
  HOME_ADMIN = "/products",
  HOME_SUPERVISOR = "/sale-funnel",
  HOME_SELLER = "/sale-funnel",
  LOST_LEADS = "/leads/lost",
  CUSTOMERS_CONVERSION = "/customers-conversion",
  PERFORMANCE_SELLER = "/performance-seller",
  LOGIN = "/",
  CUSTOMERS = "/customers",
  SALES_FUNNEL = "/sale-funnel",
  INDICATORS = "/indicators",
  SELLERS = "/sellers",
  STORE_PERFORMANCE = "/store-performance",
  SELLER_PERFORMANCE = "/sellers/performance",
  MY_CUSTOMERS = "/my-customers",
  SELLERS_GOALS = "/sellers/goals",
  PRODUCTS = "/products",
  PRODUCT_STOCK = "/products/stock",
  MONTH_RANK = "tv/:id",
  LOGISTICS = "/logistica"
}
