import api from "./api";

class UsersService {
  async create(data: any) {
    return api
      .post("/user/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async login(data: any) {
    return api
      .post("/user/login", data)
      .then((result) => {
        localStorage.setItem("@token", result.data.access_token);
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async loginComToken(data: any) {
    return api
      .post("/user/login-token", data)
      .then((result) => {
        localStorage.setItem("@token", result.data.access_token);
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAll(): Promise<any> {
    return api
      .get("/user/find-all")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getOne(): Promise<any> {
    return api
      .get("find-one/:id")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async update(userId: number, data: any): Promise<any>{
    return api
        .put(`/user/update/${userId}`, data)
        .then((result) => {
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
  }

  async remove(userId: number): Promise<any>{
    return api
        .delete(`/user/delete/${userId}`)
        .then((result) => {
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
  }
}

const usersService = new UsersService();
export default usersService;
