import IStore from "../interfaces/IStore"
import UtilDate from "../utils/util.date"
import api from "./api"

class ProductService{
    
    async getAllProductsStock(): Promise<any>{   
        return api.get("/produto/estoque").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getSummaryProductsAndOrders(startDate: Date,  endDate: Date, store?: IStore): Promise<any>{   
        let startDateString: string = UtilDate.dateToYMD(startDate)
        let endDateString: string = UtilDate.dateToYMD(endDate)
        let idStore = (store) ? store.id : null
        return api.get(`/produto/resumo-vendas?inicio=${startDateString}&fim=${endDateString}&loja=${idStore}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getSummaryStock(startDate: Date,  endDate: Date): Promise<any>{   
        let startDateString: string = UtilDate.dateToYMD(startDate)
        let endDateString: string = UtilDate.dateToYMD(endDate)
        return api.get(`/produto/resumo-estoque?inicio=${startDateString}&fim=${endDateString}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getLastUpdate(): Promise<any>{   
        return api.get("/importacao/ultima-importacao-pedido").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}

const productService = new ProductService()
export default productService