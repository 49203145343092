import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import IStore from "../../interfaces/IStore";
import storeService from "../../services/StoreService";
import "./index.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import orderService from "../../services/OrderService";
import { TextField } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IResultPerformance{
  id: number;
  name: string;
  day: number;
  total_sale: number;
  goal: number | null;
}

const SellerPerformance = () => {
  
  const getDaysInCurrentMonth = () => {
    const firstDay = 1
    const lastDay = moment().daysInMonth()

    let list = []
    for(let i=firstDay; i <= lastDay; i++){
      list.push(String(i))
    }
    return list
  }

  const labels = getDaysInCurrentMonth();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf('month').toDate()
  );
  const [goal, setGoal] = useState<number>(0)
  const [stores, setStores] = useState<IStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<IStore>();
  const [data, setData] = useState<any>()

  function onlyUnique(value: any, index: any, array: any) {
    return array.indexOf(value) === index;
  }

  let BRReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const colors = [
    "#006699",
    "#ffcc00",
    "#ff9900",
    "#cc0000",
    "#00cc00",
    "#0000cc",
    "#0000ff",
    "#ff0000",
    "#00ff00",
    "#006699",
    "#ffcc00",
    "#ff9900",
    "#cc0000",
    "#00cc00",
    "#0000cc",
    "#0000ff",
    "#ff0000",
    "#00ff00"
  ]

  const loadData = (page: number) => {
    setLoading(true);
    orderService
      .getSellerPerformance(selectedStore?.id, startDate, endDate)
      .then((response) => {
        let data: IResultPerformance[] = response.data;

        if (data.length > 0){
          if (data[0].goal){
            setGoal(data[0].goal)
          }
        }
        

        //transforma o resultado nos datasets que o gráfico espera
        let uniqueSellers = data.map((item) => item.name).filter(onlyUnique)
        let datasets = []
        let countSellers = 0
        //para cada vendedor, gera os valores vendidos por dia, preenchendo os dias faltantes com valor 0
        for(let seller of uniqueSellers){
          let listValues = []
          let accumulatedPercentage = 0
          for(let day of labels){
            let dayWithSale = data.find((item) => item.name == seller && item.day == Number(day))
            if (dayWithSale){
              //se não houver meta, preenche o valor do dia com 0
              if (dayWithSale.goal){
                let percentage = Number(dayWithSale.total_sale) / Number(dayWithSale.goal) * 100
                accumulatedPercentage += percentage                
              }
            }
            listValues.push(accumulatedPercentage)            
          }
          datasets.push({
            label: seller,
            data: listValues,
            borderColor: colors[countSellers],
            backgroundColor: colors[countSellers],
          })
          countSellers += 1
        }

        setData({
          labels: labels,
          datasets: datasets
        })

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadStores();
    loadData(1);
  }, []);

  const loadStores = () => {
    storeService
      .getAll()
      .then((response) => {
        let data: IStore[] = response.data;
        data = data.filter((store) => [203404783, 204308099, 204593611].includes(store.id) ) //apenas LOJAS DE APARECIDA, SJC e CANAL LOJISTA
        setStores(data);
      })
      .catch((error) => {});
  };

  const selectStore = (id: string) => {
    setSelectedStore(stores.find((item) => item.id == Number(id)));
  };

  const options = {
    scales: {
      y: {
        min: 0,
        stepSize: 5,
      }      
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        // text: "Chart.js Line Chart",
      },
    },
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "20px",
      }}
    >
      <Grid item xs={12} container flexDirection={"row"}>
        <Grid item xs={4}>
          <p className="titlePage">Minhas metas</p>
        </Grid>
        <Grid item md={12}>
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"center"}
            marginBottom={"5px"}
            xs={12}
          >
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="loja-selection-id-label">Loja</InputLabel>
                <Select
                  id="loja-selection-id"
                  labelId="loja-selection-id-label"
                  label={"Loja"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    selectStore(value);
                  }}
                >
                  <MenuItem value={""}>Todas</MenuItem>
                  {stores.map((store) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} padding={"5px"}>
              <TextField
                label={"Período inicial"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={moment(startDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setStartDate(moment(stringDate, "YYYY-MM-DD").toDate());
                }}
              />
            </Grid>

            <Grid item xs={3} padding={"5px"}>
              <TextField
                label={"Período final"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={"2022-02-01"}
                value={moment(endDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setEndDate(moment(stringDate, "YYYY-MM-DD").toDate());
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => loadData(1)}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{fontSize: "1.2em", fontWeight: "bold"}}>Sua Meta: {BRReal.format(goal)}</div>

      <Grid
        item
        xs={8}
        sx={{
          height: "calc(90vh - 200px)",
          overflowY: "auto",
        }}
      >
        { data != undefined && 
          <Line options={options} data={data} />
        }
      </Grid>
    </Grid>
  );
};

export default SellerPerformance;
