import api from "./api"

class StoreService{
    
    async getAll(): Promise<any>{   
        return api.get("/loja").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}



const storeService = new StoreService()
export default storeService