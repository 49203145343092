import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { TabPanel } from "../../../SalesFunnel/components/TabPanel";
import { SellerGoals } from "../sellerGoals";
import { FormGoal } from "../sellerGoals/formGoal";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import { useGoalService } from "../../../../hooks/goal";
import { GoalFormInput } from "../../../../hooks/goal/type";
import swal from "sweetalert";
import { ProductsResponse } from "../../../../hooks/product/type";

export const ModalDetailsSeller = ({
  seller,
  close,
  products,
}: {
  seller: any;
  close: () => void;
  products: ProductsResponse;
}) => {
  const [value, setValue] = useState(0);

  const { getGoalsBySellerId, delGoalById, postCreateGoal, putUpdateGoalById } =
    useGoalService();

  const handleDelete = async (id: number | undefined) => {
    await delGoalById({ id });

    swal({
      title: "Meta excluída com sucesso",
      text: "A meta foi retirada do vendedor",
      icon: "success",
    }).then((value: any) => {
      setValue(0);
    });
  };

  const handleSubmit = async (values: GoalFormInput) => {
    const { id, endDate, startDate, value, idProduct } = values;
    const bodyRequest = {
      endDate: endDate,
      startDate: startDate,
      value,
      idProduct: idProduct ? parseInt(idProduct) : undefined,
    };

    if (id) {
      await putUpdateGoalById({ id: id, bodyRequest });
    } else {
      await postCreateGoal({ sellerId: seller.id, bodyRequest });
    }

    swal({
      title: "Meta cadastrada com sucesso",
      text: "A meta foi cadastrada para o vendedor",
      icon: "success",
    }).then((value: any) => {
      setValue(1);
    });
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "fixed", width: "100%" }}
    >
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Metas - {seller.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "800px" }}>
          <Box sx={{ maxHeight: "800px" }}>
            <Grid sx={{ borderBottom: 1, borderColor: "divider" }} xs={12} item>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
              >
                <Tab
                  sx={{
                    "&:hover": {
                      backgroundColor: "none",
                    },
                  }}
                  label="Definir Nova Meta"
                />
                <Tab
                  sx={{
                    "&:hover": {
                      backgroundColor: "none",
                    },
                  }}
                  label="Metas do Vendedor"
                />
              </Tabs>
            </Grid>
            <TabPanel value={value} index={0}>
              <FormGoal
                products={products}
                close={close}
                handleSubmit={handleSubmit}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SellerGoals
                products={products}
                handleDelete={handleDelete}
                sellerId={seller.id}
                close={close}
              />
            </TabPanel>
          </Box>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
};
