import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { parse } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BsFillCaretDownFill,
  BsFillCaretUpFill,
  BsFillExclamationCircleFill,
} from "react-icons/bs";
import * as XLSX from "xlsx";
import IImportData from "../../interfaces/IImportData";
import ISortType from "../../interfaces/ISortType";
import IStore from "../../interfaces/IStore";
import productService from "../../services/ProductService";
import storeService from "../../services/StoreService";
import UtilDate from "../../utils/util.date";
import "./index.scss";
registerLocale("pt-BR", ptBR);

interface IProduct {
  [key: string]: string | undefined | number;
  id: number;
  descricao: string;
  codigo: string;
  pedidos: number;
  itensVendidos: number;
  itensVendidosAnterior: number;
  receita: number;
  receitaPorItem: number;
  precoCusto: number;
  curvaABC: string;
  crescimentoVendas: number;
  margemContribuicao: number;
  categoria: string;
}

const ProductList = () => {
  const [items, setItems] = useState<IProduct[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(30, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [orderBy, setOrderBy] = useState<string>("itensVendidos");
  const [sortDirection, setSortDirection] = useState<string>("DESC");
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleShowTooltip = () => setShowTooltip(!showTooltip);
  const [position, setPosition] = useState("top-start");
  const [filterSku, setFilterSku] = useState<string>("");
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [filteredProducts, setFilteredProdcuts] = useState<IProduct[]>([]); //copy of "items" list to show a filtered list
  const [stores, setStores] = useState<IStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<IStore>();

  const sortTypes: ISortType[] = [
    { column: "codigo", type: "string" },
    { column: "descricao", type: "string" },
    { column: "categoria", type: "string" },
    { column: "pedidos", type: "number" },
    { column: "itensVendidos", type: "number" },
    { column: "itensVendidosAnterior", type: "number" },
    { column: "receita", type: "number" },
    { column: "receitaPorItem", type: "number" },
    { column: "precoCusto", type: "number" },
    { column: "curvaABC", type: "string" },
    { column: "crescimentoVendas", type: "number" },
    { column: "margemContribuicao", type: "number" },
  ];

  const loadList = (page: number) => {
    setLoading(true);
    productService
      .getSummaryProductsAndOrders(startDate, endDate, selectedStore)
      .then((response) => {
        let products: IProduct[] = response.data;

        if (products.length > 0) {
          products = products.map((product) => {
            if (
              product.itensVendidos > 0 &&
              product.itensVendidosAnterior > 0
            ) {
              product.crescimentoVendas =
                Math.round(
                  (((product.itensVendidos - product.itensVendidosAnterior) *
                    100) /
                    product.itensVendidosAnterior) *
                    100
                ) / 100;
            } else {
              if (product.itensVendidos == 0) {
                //caiu todo o número de vendas
                product.crescimentoVendas =
                  -product.itensVendidosAnterior * 100;
              } else {
                //subiu o número de vendas de 0 para algum valor
                product.crescimentoVendas = product.itensVendidos * 100;
              }
            }
            return product;
          });

          products = curveABC(products);

          products = sortProducts(products, orderBy);
        }

        setItems(products);

        products = filterList(products);
        setFilteredProdcuts(products);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const curveABC = (products: IProduct[]) => {
    let auxProducts = sortProducts(products, "receita");

    let totalRevenue: number = auxProducts
      .map((product) => Number(product.receita))
      .reduce((a, b) => a + b);

    let sum = 0;
    let index;
    for (index = 0; index < auxProducts.length; index++) {
      let product = auxProducts[index];
      sum += Number(product.receita);
      product.curvaABC = "A";
      if (sum >= (80 / 100) * totalRevenue) {
        break;
      }
    }

    for (; index < auxProducts.length; index++) {
      let product = auxProducts[index];
      sum += Number(product.receita);
      product.curvaABC = "B";
      if (sum >= (95 / 100) * totalRevenue) {
        break;
      }
    }

    for (; index < auxProducts.length; index++) {
      let product = auxProducts[index];
      product.curvaABC = "C";
    }

    //copia a propriedade curvaABC para a lista original
    for (let product of auxProducts) {
      let objProduct = products.find((p) => p.id == product.id);
      if (objProduct) {
        objProduct.curvaABC = product.curvaABC;
      }
    }
    return products;
  };

  const sortProducts = (products: IProduct[], column: string) => {
    //ordena a lista pelo atributo selecionado para ordenação
    products = products.sort((a, b): any => {
      let value1: any = a[column];
      let value2: any = b[column];

      const sortType: ISortType | undefined = sortTypes.find(
        (item) => item.column == column
      );
      if (sortType?.type == "string") {
        if (sortDirection == "DESC") {
          return value1.localeCompare(value2);
        } else {
          return value2.localeCompare(value1);
        }
      } else {
        if (sortDirection == "DESC") {
          if (Number(value1) < Number(value2)) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (Number(value1) > Number(value2)) {
            return 1;
          } else {
            return -1;
          }
        }
      }
    });
    return products;
  };

  const getLastUpdate = () => {
    productService
      .getLastUpdate()
      .then((response) => {
        let data: IImportData = response.data;
        let momentDate = moment(data.dataHora, "YYYY-MM-DDTHH:mm:ss").toDate();
        data.dataHora = momentDate;
        setLastUpdate(data.dataHora);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    loadStores();
    loadList(1);
    getLastUpdate();
  }, []);

  const loadStores = () => {
    storeService
      .getAll()
      .then((response) => {
        let data: IStore[] = response.data;
        setStores(data);
      })
      .catch((error) => {});
  };

  const handleHeadClick = (column: string) => {
    let direction = sortDirection;
    if (orderBy == column) {
      direction = sortDirection == "ASC" ? "DESC" : "ASC";
    } else {
      direction = "DESC";
    }
    setOrderBy(column);
    setSortDirection(direction);

    let products = sortProducts(items, column);
    setFilteredProdcuts(products);
  };

  const filterList = (products: IProduct[]) => {
    if (filterSku != "") {
      products = products.filter((product) => {
        return (
          product.codigo.toLowerCase().indexOf(filterSku.toLowerCase()) >= 0
        );
      });
    }

    if (filterCategory != "") {
      products = products.filter((product) => {
        return (
          product.categoria
            .toLowerCase()
            .indexOf(filterCategory.toLowerCase()) >= 0
        );
      });
    }

    return products;
  };

  useEffect(() => {
    let products = items;
    products = filterList(products);
    setFilteredProdcuts(products);
  }, [filterSku, filterCategory]);

  const selectStore = (id: string) => {
    setSelectedStore(stores.find((item) => item.id == Number(id)));
  };

  const exportExcel = () => {
    const csvData: any[] = filteredProducts;
    const fileName = "produtos";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { Produtos: ws }, SheetNames: ["Produtos"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const teste = {
    backgroundColor: "#fff"
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "10px",
      }}
    >
      <Grid item xs={12} container flexDirection={"row"} style={teste}>
        <Grid item xs={4} className="titlePage">
          <p>Relatório de Produtos</p>
          {lastUpdate && (
            <p>
              <>Atualizado em {UtilDate.dateToDMYHM(lastUpdate)}</>
            </p>
          )}
        </Grid>
        <Grid item md={8}>
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginBottom={"10px"}
            xs={12}
          >
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id="loja-selection-id-label">Loja</InputLabel>
                <Select
                  id="loja-selection-id"
                  labelId="loja-selection-id-label"
                  label={"Loja"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const { value } = event.target;
                    selectStore(value);
                  }}
                >
                  <MenuItem value={""}>Todas</MenuItem>
                  {stores.map((store) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={"Período inicial"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setStartDate(moment(stringDate, "YYYY-MM-DD").toDate());
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label={"Período final"}
                type={"date"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const stringDate = e.target.value;
                  setEndDate(moment(stringDate, "YYYY-MM-DD").toDate());
                }}
              />
            </Grid>
          </Grid>

          <Grid className="form-sections" item xs={12}>
            <Grid item xs={5}>
              <TextField
                label={"SKU"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterSku(value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={"Categoria"}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  const { value } = event.target;
                  setFilterCategory(value);
                }}
              />
            </Grid>
          </Grid>

          <Grid item className="form-sections" xs={12}>
            <Grid item xs={5}></Grid>
          </Grid>

          <Grid item className="form-sections" md={5}>
            <Grid item xs={5}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => loadList(1)}
              >
                Filtrar
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => exportExcel()}
              >
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          height: "calc(90vh - 200px)",
          overflowY: "auto",
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr className="tr">
              <th>
                <div className="icon">
                  <a
                    onClick={() => handleHeadClick("descricao")}
                    className="clicavel"
                  >
                    Produto
                    {orderBy == "descricao" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <a
                    onClick={() => handleHeadClick("codigo")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    SKU
                    {orderBy == "codigo" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <a
                    onClick={() => handleHeadClick("categoria")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    Categoria
                    {orderBy == "categoria" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <Form.Check type="checkbox" />
                  <a
                    onClick={() => handleHeadClick("itensVendidos")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    Qtde. Vendas
                    {orderBy == "itensVendidos" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <Form.Check type="checkbox" label=" " />
                  <a
                    onClick={() => handleHeadClick("receita")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    Receita (R$)
                    {orderBy == "receita" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <Form.Check type="checkbox" label=" " />
                  <a
                    onClick={() => handleHeadClick("curvaABC")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    Curva ABC
                    {orderBy == "curvaABC" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <Form.Check type="checkbox" label=" " />
                  <a
                    onClick={() => handleHeadClick("crescimentoVendas")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    +/- Vendas (%)
                    {orderBy == "crescimentoVendas" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                    <Button
                      onClick={toggleShowTooltip}
                      className="mb-2"
                      variant="gray"
                    >
                      <strong>
                        <BsFillExclamationCircleFill />
                      </strong>
                    </Button>
                  </a>
                </div>
              </th>

              <th>
                <div className="icon">
                  <Form.Check type="checkbox" />
                  <a
                    onClick={() => handleHeadClick("margemContribuicao")}
                    href="javascript: void(0)"
                    className="clicavel"
                  >
                    Margem (%)
                    {orderBy == "margemContribuicao" && (
                      <>
                        {sortDirection == "ASC" && <BsFillCaretDownFill />}
                        {sortDirection == "DESC" && <BsFillCaretUpFill />}
                      </>
                    )}
                  </a>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={8}>
                  <p>Carregando...</p>
                </td>
              </tr>
            )}
            {filteredProducts.map((item: IProduct) => (
              <tr key={item.id}>
                <td className="descricao">
                  <span>{item.descricao}</span>
                </td>
                <td>
                  <span>{item.codigo}</span>
                </td>
                <td>
                  <span>{item.categoria}</span>
                </td>
                <td>
                  <span>{item.itensVendidos}</span>
                </td>
                <td>
                  <span>R$ {item.receita.toString().replace(".", ",")}</span>
                </td>
                <div
                  style={{
                    backgroundColor:
                      item.curvaABC == "A"
                        ? "#C1FFC8"
                        : item.curvaABC == "B"
                        ? "#FFECA8"
                        : "#FFC98A",
                  }}
                >
                  <td>
                    <span>{item.curvaABC}</span>
                  </td>
                </div>
                <td
                  style={{
                    color: item.crescimentoVendas > 0 ? "#090" : "#c00",
                  }}
                >
                  <span>{item.crescimentoVendas}</span>
                </td>
                <td
                  style={{
                    color: item.margemContribuicao < 0 ? "#c00" : "#000",
                  }}
                >
                  <span>{Math.round(item.margemContribuicao * 100) / 100}</span>
                </td>
              </tr>
            ))}

            {filteredProducts.length == 0 && !isLoading && (
              <tr>
                <td colSpan={8}>
                  <p>Nenhum produto encontrado.</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="info">
          <div>
            <Row>
              <Col md={6} className="mb-2">
                <ToastContainer className="p-3" position={"top-end"}>
                  <Toast show={showTooltip} onClose={toggleShowTooltip}>
                    <Toast.Header>
                      <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                      />
                      <strong className="me-auto">
                        <BsFillExclamationCircleFill />
                      </strong>
                    </Toast.Header>
                    <Toast.Body>
                      <h6>
                        {/* Em relação ao período de <span>{UtilDate.dateToDMY(startDate)}</span> a <span>{UtilDate.dateToDMY(endDate)}</span> */}
                        Em relação ao mesmo período de dias antes de{" "}
                        <span>{UtilDate.dateToDMY(startDate)}</span>
                      </h6>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProductList;
