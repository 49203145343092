import api from "../shared/axios";
import UtilDate from "../utils/util.date";

class CustomersService {
  async getAll(): Promise<any> {
    return api
      .get("/customer/find-all")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async create(data: any) {
    return api
      .post("/customer/create", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCustomersOrdersSum(period: string): Promise<any> {
    return api
      .get(`/orders/customers-orders-sum?period=${period}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCustomersConversion(startDate: Date, endDate: Date): Promise<any> {
    let startDateString: string = UtilDate.dateToYMD(startDate);
    let endDateString: string = UtilDate.dateToYMD(endDate);
    return api
      .get(
        `/customer/customers-conversion?inicio=${startDateString}&fim=${endDateString}`
      )
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const customersService = new CustomersService();
export default customersService;
