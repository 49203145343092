import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, Fragment, useState } from "react";
import { RowProps } from "../../type";

export const Row: FC<RowProps> = ({ subContent, values, updateSubContent }) => {
  const [open, setOpen] = useState(false);

  if (updateSubContent){
    updateSubContent = React.cloneElement(updateSubContent, {setOpen: setOpen})
  }

  return (
    <Fragment>
      <TableRow>
        {subContent && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ height: "40px", width: "40px" }}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}

        {values.map((value, id) => (
          <TableCell key={id} align={"left"}>
            {value}
          </TableCell>
        ))}
      </TableRow>

      {subContent && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div style={{ border: "solid grey", margin: '0px 0px 20px'}}>
                <Box sx={{ margin: 2 }}>{subContent}</Box>
                <Box sx={{ margin: 2 }}>{updateSubContent}</Box>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};
